import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import templateId from '../../../../libraries/lang-id';
import languange from '../../../../libraries/label-lang';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Copyright from '../../../presentational/copyright';
import {connect} from 'react-redux';
import {RootActions} from "../../../../shared/root-action";
import Logo from "../../../../images/al-azhar-180x180.png";
import {history} from "../../../../shared/configure-store";
import {toast, ToastContainer} from "react-toastify";
import Api from "../../../../libraries/api";
import {bindActionCreators} from "redux";
import AuthHelper from '../../../../libraries/auth-helper';

require('dotenv').config();

SimpleReactValidator.addLocale('indo', templateId);

class Forgot extends Component {

    constructor(props) {

        super(props);

        let lang = AuthHelper.getLang();

        this.validator = new SimpleReactValidator(
            {
                locale: lang
            }
        );

        this.state = {
            loading: false,
            showPassword: false,

            errors: [],
            email: '',
            password: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChecked = this.handleChecked.bind(this);
        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        document.title = 'PSIKOTES ONLINE - Forgot';
    }

    componentWillReceiveProps() {

        let lang = AuthHelper.getLang();

        this.validator = new SimpleReactValidator(
            {
                locale: lang
            }
        );
    }

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        })

    };

    handleChecked = name => e => {

        this.setState({

            [name]: e.target.checked ? 1 : 0,

        })

    };

    handleClickShowPassword = () => {

        this.setState({

            showPassword:(!this.state.showPassword)

        });
    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleSubmit = e => {

        e.preventDefault();

        if (!this.validator.allValid()) {

            this.setState({
                    errors: this.validator.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loading: true,
            }
        );

        let params = {

            email: this.state.email,
            reseturl : process.env.REACT_APP_SERVER_URL+'/forgot-reset',

        };

        Api.post('/password/forgot', params).then(resp => {

            history.push('/forgot-success');
        }).catch(err => {

            this.setState({
                    errors: err,
                    loading: false
                }
            );

        });

    };

    render() {

        let lang = {};
        if(this.props.toggle_set_lang === 'indo') {
            lang = languange.id;
        }else {
            lang = languange.en;
        }
        const text = lang;

        return (
            <main className='login'>
                <section>
                    <div className='container position-relative'>
                        <div className='row align-items-end m-0 row-login'>
                            <div className='col-md-6 col-lg-7 px-lg-7 text-center text-md-left'>
                                <img src={Logo} alt='logo' className='img-logo' />
                                {/* <h1 className='title'>PSIKOTES ONLINE</h1> */}
                                {/* <h1 className='sub-title'>Psikotes</h1> */}
                            </div>
                            <div className='col-md-6 col-lg-5 px-lg-5 text-center'>
                                <h1>{text.forgot_heading}?</h1>
                                <p>{text.forgot_paragraph}</p>
                                <form name='login' id='login' noValidate>
                                    <div className='form-group'>
                                        <TextField
                                            type='text'
                                            id='email'
                                            name='email'
                                            label={text.label_email}
                                            placeholder={text.label_email}
                                            onChange={(e) => this.handleChange(e, 'email')}
                                            value={this.state.email}
                                            fullWidth
                                        />
                                        {this.validator.message('email', this.state.email, 'email|required')}
                                        <div className='text-danger'>{this.state.errors.email}</div>
                                    </div>
                                    <div className='row align-items-center mt-3'>
                                        <div className='col-6 text-left'>
                                            <Link to='/login' className='text-blue'>{text.button_back}</Link>
                                        </div>
                                        <div className='col-6'>
                                            <Button
                                                variant='contained'
                                                onClick={this.handleSubmit}
                                                color='primary'
                                                disabled={this.state.loading}
                                                className='full'
                                                type='submit'
                                            >
                                                {text.button_next} { this.state.loading && <i className='fa fa-spinner fa-spin'> </i> }
                                            </Button>
                                        </div>
                                    </div>
                                    <div className='text-danger mb-2'>{this.state.errors.message}</div>
                                </form>
                            </div>
                            <div className='col-12 mt-auto footer-float'>
                                <div className='row justify-content-end'>
                                    <div className='col-md-6 col-lg-5'>
                                        <footer>
                                            <p className='text-center d-none d-md-block'><Copyright /></p>
                                        </footer>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                <footer>
                    <p className='text-center d-md-none'><Copyright /></p>
                </footer>

                <ToastContainer autoClose={false} />
            </main>
        )

    }
}

const mapStateToProps = (state) => {

    return {

        profile_state: state.profile_state,

        toggle_set_lang: state.toggle_set_lang,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(Forgot);
