import React,{Component} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '../../../presentational/table-pagination-actions';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import AuthHelper from '../../../../libraries/auth-helper';
import CircularProgress from '@material-ui/core/CircularProgress';
import {history} from '../../../../shared/configure-store';
import {toast, ToastContainer} from 'react-toastify';
import { withRouter, Link } from 'react-router-dom';
import Api from '../../../../libraries/api';
import languange from '../../../../libraries/label-lang';
import {bindActionCreators} from 'redux';
import {RootActions} from '../../../../shared/root-action';
import {connect} from 'react-redux';
import SimpleReactValidator from "simple-react-validator";
import { SearchInput } from "../../styling";
import moment from "moment";

class MasterQuestions extends Component {


    constructor(props) {
        let lang = AuthHelper.getLang();

        super(props);

        this.state = {
            loading: false,
            loadingButton: false,

            errors: [],

            showDialog: false,
            idDelete: '',
            currDelete: '',

            orderBy: '',
            sortedBy: '',
            searchBy: '',
            searchByTheoryId: '',
            searchByUserId: '',
            keyword: '',

            rows: [],
            total: 0,
            perPage: 10,
            currentPage: 1,
            currentPageTable: 0,

            warehouses: [],

            showActivate :false,
            idChange: '',
            currChange: '',
            dataChange:'',
            accessChange: '',

        };

        this.validator = new SimpleReactValidator(
            {
                locale: lang
            }
        );
    }

    componentDidMount() {

        document.title = 'PSIKOTES ONLINE - Master Kategori';

        this._loadData()
    };

    _loadData = _ => {
        this.setState({
            loading: true,
            rows:[]
        })
        const {
            perPage,
            currentPage,
            keyword,
            searchByTheoryId,
            searchByUserId
        } = this.state

        const params = {//pagination:{
            ...(perPage ? { size: Number(perPage) } : {}),
            ...(currentPage ? { page: Number(currentPage) } : {}),
            ...(keyword ? { search : keyword } : {}),
            ...(searchByTheoryId ? { theoryId : searchByTheoryId } : {}),
            ...(searchByUserId ? { userId : searchByUserId } : {}),
        };//};

        Api.post('/questions/schedules',params).then(resp => {
            let response = resp?.result ?? {};

            this.setState({
                rows: response?.data ?? [],
                loading: false,
                // paginations
                perPage: response?.pagination && response.pagination.size ? response.pagination.size : this.state.perPage,
                currentPage: response?.pagination && response?.pagination.currentPage ? response?.pagination.currentPage -1 : this.state.currentPage,
                currentPageTable: response?.pagination && response?.pagination.currentPage ? response?.pagination.currentPage -1 : this.state.currentPageTable,
                total: response?.pagination && response?.pagination.totalItems ? response?.pagination.totalItems : this.state.total,
            });
        }).catch(e => {
            this.showMessage(false, e.message);
            this.setState({ loading: false, })
        })

    }

    handleClose = () => {
        this.setState({
            showDialog: false,
            showActivate: false
        })
    };

    handleDelete = () => {

        if (this.state.idDelete) {
            Api.delete('/reservations/' + this.state.idDelete, '', false).then(resp => {

                this.setState({
                        showDialog: false,
                    }
                );

                this.showMessage(true, 'Successfully deleted');
                this._loadData()

            }).catch(err => {

                this.setState({
                        showDialog: false
                    }
                );

                this.showMessage(true, 'Successfully deleted');
                this._loadData()
            });
        }
    };

    handleGo = (link) => {
        history.push(link);
    };

    showMessage = (status, message) => {
        if (status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        } else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleOpen = (row) => {
        this.setState({
            showActivate :true,
            idChange: row.id,
            currChange: row.name,
            dataChange: row,
            accessChange: row.active
        })
    };

    handleChange = () => {
        if (this.state.idChange) {
            let lang = {};
            if (this.props.toggle_set_lang === 'indo') {
                lang = languange.id;
            } else {
                lang = languange.en;
            }
            const text = lang;

            Api.put('/questions/status/' + this.state.idChange, {
                active:!this.state.accessChange,
                theoryId: this.state.dataChange.theoryId,
                userId: this.state.dataChange.userId,
            }).then(resp => {

                this.setState({
                        showActivate: false,
                    }
                );

                this.showMessage(true, text.label_data_success+(this.state.accessChange ? text.label_deactivation : text.label_activation));
                this._loadData()

            }).catch(err => {

                this.setState({
                        showActivate: false
                    }
                );

                this.showMessage(true, err.message);
                this._loadData()
            });
        }
    };

    handleSort = (val) => {

        let sortedBy = this.state.sortedBy;

        if (val !== this.state.orderBy) {
            sortedBy = 'asc';
        } else {
            if (sortedBy && sortedBy === 'asc') {
                sortedBy = 'desc';
            } else {
                sortedBy = 'asc';
            }
        }

        this.setState({
            orderBy: val,
            sortedBy: sortedBy,
            loading: true
        }, () => {
            this._loadData()
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            rows: [],
            currentPage: newPage + 1,
            currentPageTable: newPage + 1,
            loading: true,
        }, () => {
            this._loadData()
        });
    };

    handleChangeRowsPerPage = event => {
        this.setState({
            perPage: parseInt(event.target.value, 10),
            currentPage: 1,
            loading: true,
        }, () => {
            this._loadData()
        });
    };


    __handleSearchEvent = (data,props)=>{

        this.setState({
            [props]:data ? data : ''
        },()=> {
            this._loadData();
        });
    };


    render() {
        let lang = {};
        if (this.props.toggle_set_lang === 'indo') {
            lang = languange.id;
        } else {
            lang = languange.en;
        }
        const text = lang;
        // const warehouse_data = useFetch("/warehouses");

        return (

            <div className='dashboard-detail'>
                <nav aria-label='breadcrumb'>
                    <ol className='breadcrumb'>
                        <li className='breadcrumb-item'><Link to='/' >{text.label_dashboard}</Link></li>
                        <li className='breadcrumb-item active' aria-current='page'>{text.label_schedules}</li>
                    </ol>
                </nav>
                <h2 className='text-uppercase'>{text.label_schedules}
                    {/*<Button*/}
                    {/*    variant='contained'*/}
                    {/*    color='primary'*/}
                    {/*    className='float-right mb-3'*/}
                    {/*    onClick={() => this.handleGo('/master_questions/add')}*/}
                    {/*>*/}
                    {/*    {text.button_add} {text.label_schedules}*/}
                    {/*</Button>*/}
                </h2>

                <div className='d-inline-flex align-items-center w-100 justify-content-between my-2'>
                    <SearchInput
                        placeholder={text.label_browse + ' ' + text.label_questions}
                        value={this.state.keyword}
                        onSubmit={(x) => this.__handleSearchEvent(x, 'keyword')}
                        onKeyPress={(x) => this.__handleSearchEvent(x, 'keyword')}
                        onKeyUp={(x) => this.__handleSearchEvent(x, 'keyword')}
                    />
                </div>

                <div className='clearfix'> </div>
                <div className='table-wrapper'>
                    <Table className='table-list' size='small' >
                        <TableHead>
                            <TableRow>
                                {/*<TableCell>{text.label_photo || `picture`}</TableCell>*/}
                                <TableCell >{text.label_name}</TableCell>
                                <TableCell >{text.label_schedules_name}</TableCell>
                                {/*<TableCell >{text.label_category}</TableCell>*/}
                                <TableCell align='center' >{text.label_duration}</TableCell>
                                <TableCell >{text.label_questions_expire}</TableCell>
                                <TableCell align='center' >{text.label_status}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.loading ? (
                                <TableRow>
                                    <TableCell colSpan={5} align='center' className='py-5'>
                                        <CircularProgress color='primary' />
                                    </TableCell>
                                </TableRow>
                            ) : (
                                this.state.rows && this.state.rows.length === 0 ? (
                                    <TableRow style={{ height: 33 * this.state.perPage }}>
                                        <TableCell colSpan={5} style={{ textAlign: 'center' }}>{text.label_no}</TableCell>
                                    </TableRow>
                                ) : (
                                    this.state.rows.map((row, i) => (
                                        <TableRow
                                            // key={i}
                                            style={{ cursor: 'pointer' }}
                                            // onDoubleClick={_ =>{
                                            onClick={_ => {
                                                history.push({
                                                    pathname: 'schedules/' + row.id,
                                                    state: row
                                                })
                                            }}
                                            key={row.id}
                                            selected={row.selected}>
                                            {/*<TableCell>*/}
                                            {/*    <img src={row.file ? process.env.REACT_APP_API_SERVER_PRODUCT_URL+'/file'+row.file : Logo2}*/}
                                            {/*     style={{ width: 50, height: 50, background: `lightgrey` }} />*/}
                                            {/*</TableCell>*/}
                                            <TableCell>{row?.user?.name ? row.user.name : '-'}</TableCell>
                                            <TableCell>{
                                                row?.theory?.name ? row.theory?.name +(
                                                    row?.theory?.category?.name ?
                                                    ', '+ row?.theory?.category?.name + (row?.theory?.subcategory?.name ? ' - '+row?.theory?.subcategory?.name : '')
                                                    : '')
                                                    : ''
                                            }</TableCell>
                                            <TableCell><div className='text-center'>{row?.theory?.duration ? row.theory.duration : 0} {text.label_minute}</div></TableCell>
                                            {/*<TableCell><NumberFormat thousandSeparator={true} value={row.hargaJual ? row.hargaJual : row.harga ? row.harga+(row.harga * 20 /100) : 0} displayType={'text'} renderText={value => value} prefix={'Rp. '} /></TableCell>*/}
                                            <TableCell>{row.expire ? moment(row.expire).format('DD MMM YYYY'): '-'}</TableCell>
                                            {/*<TableCell>{row.terjual ? row.terjual : '-'}</TableCell>*/}
                                            <TableCell><div className='text-center'>{row.answerId ?
                                                <label className='w-75 badge badge-success' onClick={() => this.handleOpen(row)}>{text.label_testing_after}</label> :
                                                <label className='w-75 badge badge-dark' onClick={() => this.handleOpen(row)} >{text.label_testing_before}</label>
                                            }</div></TableCell>
                                        </TableRow>
                                    ))
                                )
                            )}

                        </TableBody>
                    </Table>
                </div>

                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component='div'
                    className='responsive'
                    count={this.state.total}
                    rowsPerPage={this.state.perPage}
                    page={this.state.currentPageTable}
                    backIconButtonProps={{
                        'aria-label': 'previous page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'next page',
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                />

                <Dialog
                    maxWidth='sm'
                    fullWidth={true}
                    open={this.state.showDialog}
                    onClose={this.handleClose}
                    aria-labelledby='form-dialog-title'>
                    <DialogTitle id='form-dialog-title'>DELETE PROJECT: {this.state.currDelete}</DialogTitle>
                    <DialogContent>
                        {/*<p>{text.text_delete}</p>*/}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='contained'
                            className='mr-3'
                            onClick={this.handleClose}
                        >
                            {text.button_cancel}
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            className=''
                            onClick={this.handleDelete}
                            disabled={this.state.loadingButton && 'disabled'}
                        >
                            {text.button_delete}{this.state.loadingButton && <i className='fa fa-spinner fa-spin'> </i>}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    maxWidth='sm'
                    fullWidth={true}
                    open={this.state.showActivate}
                    onClose={this.handleClose}
                    aria-labelledby='form-dialog-title'>
                    <DialogTitle id='form-dialog-title'>{this.state.accessChange ? text.label_deactivation : text.label_activation} {text.label_questions+' '+text.body_for} "{this.state?.dataChange?.user?.name}" {text.label_now}?</DialogTitle>
                    <DialogContent>
                        {/*<p>{text.text_delete}</p>*/}
                    </DialogContent>
                    <DialogActions className='text-center'>
                        <Button
                            variant='contained'
                            className='mr-3'
                            onClick={this.handleClose}
                        >
                            {text.button_no}
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            className=''
                            onClick={this.handleChange}
                            disabled={this.state.loadingButton && 'disabled'}
                        >
                            {text.button_yes}{this.state.loadingButton && <i className='fa fa-spinner fa-spin'> </i>}
                        </Button>
                    </DialogActions>
                </Dialog>

                <ToastContainer />
            </div>

        )

    }
}

const mapStateToProps = (state) => {

    return {

        toggle_set_lang: state.toggle_set_lang,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MasterQuestions));
