import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import templateId from '../../../../libraries/lang-id';
import languange from '../../../../libraries/label-lang';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import {history} from '../../../../shared/configure-store';
import {connect} from 'react-redux';
import {RootActions} from '../../../../shared/root-action';
import Logo2 from '../../../../images/logo-uai-ypi-big-300x97.png';
import {bindActionCreators} from 'redux';
import {toast, ToastContainer} from 'react-toastify';
import AuthHelper from '../../../../libraries/auth-helper';
import Copyright from '../../../presentational/copyright';
import Api from '../../../../libraries/api';
import MenuItem from "@material-ui/core/MenuItem";

require('dotenv').config();

SimpleReactValidator.addLocale('indo', templateId);

class Register extends Component {

    constructor(props) {

        super(props);

        let lang = AuthHelper.getLang();

        this.validator = new SimpleReactValidator(
            {
                locale: lang
            }
        );

        this.state = {
            loading: false,
            showPassword: false,
            showPasswordConfirm: false,

            akses:'',
            errors: {},
            listGender:[{
                name: '--- Gender ---',
                value: '',
            },{
                name: 'Laki-laki',
                value: 'Laki-laki',
            },{
                name: 'Perempuan',
                value: 'Perempuan',
            }],

            name:'',
            address:'',
            gender:'',

            username:'',
            password: '',
            passwordConfirm:'',

        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
        this.handleClickShowPasswordConfirm = this.handleClickShowPasswordConfirm.bind(this);
    }

    componentDidMount() {
        document.title = 'PSIKOTES ONLINE - Register';
        this.setState({
            error:{},
            listaddress:[],
            address:'',
            gender:'',
            listDistrict:[],
            district:'',
            listSubDistrict:[],
            subDistrict:'',
            listReligion:[],
            religion:'',
            listRules:[{id:'guru',name:'Guru'},{id:'ortu',name:'Orang Tua/Wali'},{id:'siswa',name:'Siswa'}],
        });

    }

    componentWillReceiveProps() {

        let lang = AuthHelper.getLang();

        this.validator = new SimpleReactValidator(
            {
                locale: lang
            }
        );
    }

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleSubmit = e => {

        e.preventDefault();

        if (!this.validator.allValid()) {

            this.setState({
                    errors: this.validator.getErrorMessages()
                }
            );

            return false;

        }

        if (this.state.password !== this.state.passwordConfirm) {

            this.setState({
                    errors: {
                        password: 'Kata sandi harus sama',
                        passwordConfirm: 'Konfirmasi kata sandi harus sama',
                    }
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loading: true,
            }
        );

        let params = {

            nip: this.state.nip,
            name: this.state.name,
            address: this.state.address,
            username: this.state.username,
            gender: this.state.gender,
            password: this.state.password,
            access:0,
            role:3,
        };

        Api.post('/register', params).then(resp => {

            this.setState({
                    loading: false,
                }
            );
            setTimeout(()=>{
                history.push('login');

                this.showMessage(true, 'Register successfully');

            },1000);

        }).catch(err => {

            let errors = {};
            Object.keys(err).map((item) => {
                if(item){
                    errors[item] = err[item][0] ? err[item][0] : err[item];
                }
                return item;
            });

            this.setState({
                    errors: errors ? errors : {},
                    loading: false
                }
            );

            this.showMessage(false, err.message ?? 'Register Failed, Nip or Username Exist!');

        });

    };

    handleChange (e, prop){

        this.setState({

            [prop]: e && e.target && e.target.value ? e.target.value : ''

        })
    };

    handleClickShowPassword = () => {

        this.setState({

            showPassword:(!this.state.showPassword)

        });
    };

    handleClickShowPasswordConfirm = () => {

        this.setState({

            showPasswordConfirm:(!this.state.showPasswordConfirm)

        });
    };

    handleGenerateNIP = () =>{
        let x = Math.floor(Math.random() * 9999999999999999);
        // TextField.bind('nip',x);
        this.setState({
            nip: x
        });
    }

    render() {

        let lang = {};
        if(this.props.toggle_set_lang === 'indo') {
            lang = languange.id;
        }else {
            lang = languange.en;
        }
        const text = lang;

        return (
            <main className='login'>
                <section>
                    <div className='container position-relative'>
                        <div className='row align-items-end m-0 row-login'>

                            <div className='col-md-6 col-lg-7 px-lg-7 text-center text-md-left'>
                                <img src={Logo2} alt='logo' className='img-logo' />
                                {/* <h1 className='title'>PSIKOTES ONLINE</h1> */}
                                {/* <h1 className='sub-title'>Psikotes</h1> */}
                            </div>
                            <div className='col-md-6 col-lg-5 px-lg-5 text-center'>
                                <h1>{text.register_heading}</h1>
                                <p>{text.register_have_account}? <Link to='/login'>{text.register_login}</Link>.</p>
                                <form name='register' id='register' noValidate>
                                    <div className='form-group'>
                                        <TextField
                                            type='text'
                                            id='nip'
                                            name='nip'
                                            label={text.label_nip}
                                            placeholder={this.state.nip ? this.state.nip : text.label_nip}
                                            onChange={(e) => this.handleChange(e, 'nip')}
                                            value={this.state.nip}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        <IconButton
                                                            aria-label='Toggle password visibility'
                                                            onClick={this.handleGenerateNIP}
                                                            className='pass'
                                                        ><i className='fas fa-sync-alt'> </i>
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                            fullWidth
                                        />
                                        {/*<IconButton*/}
                                        {/*    className='col-2 btn bg-success'*/}
                                        {/*    onClick={()=>this.handleGenerateNIP()}*/}
                                        {/*    */}
                                        {/*>*/}
                                        {/*</IconButton>*/}
                                        {this.validator.message('nip', this.state.nip, 'required')}
                                        <div className='text-danger'>{this.state.errors.nip}</div>
                                    </div>
                                    <div className='form-group'>
                                        <TextField
                                            type='text'
                                            id='name'
                                            name='name'
                                            label={text.label_name}
                                            placeholder={text.label_name}
                                            onChange={(e) => this.handleChange(e, 'name')}
                                            value={this.state.name}
                                            fullWidth
                                        />
                                        {this.validator.message('name', this.state.name, 'required')}
                                        <div className='text-danger'>{this.state.errors.name}</div>
                                    </div>
                                    <div className='form-group'>
                                        <TextField
                                            type='text'
                                            id='address'
                                            name='address'
                                            label={text.label_address}
                                            placeholder={text.label_address}
                                            onChange={(e) => this.handleChange(e, 'address')}
                                            value={this.state.address}
                                            fullWidth
                                            multiline
                                            rows={4}
                                        />
                                        {this.validator.message('address', this.state.address, 'required')}
                                        <div className='text-danger'>{this.state.errors.address}</div>
                                    </div>

                                    <div className='form-group'>
                                        <TextField
                                            select
                                            id='gender'
                                            name='gender'
                                            label={text.label_gender}
                                            onChange={(e) => this.handleChange(e, 'gender')}
                                            value={this.state.gender}
                                            fullWidth
                                        >
                                            {this.state.listGender.map((data, idl) => (
                                                <MenuItem key={idl} value={data.value}>
                                                    {data.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        {this.validator.message('gender', this.state.gender, 'required')}
                                        <div className='text-danger'>{this.state.errors.gender}</div>
                                    </div>
                                    <div className='form-group'>
                                        <TextField
                                            type='text'
                                            id='username'
                                            name='username'
                                            label={text.label_username}
                                            placeholder={text.label_username}
                                            onChange={(e) => this.handleChange(e, 'username')}
                                            value={this.state.username}
                                            fullWidth
                                        />
                                        {this.validator.message('username', this.state.username, 'required')}
                                        <div className='text-danger'>{this.state.errors.username}</div>
                                    </div>
                                    <div className='form-group'>
                                        <TextField
                                            type={this.state.showPassword ? 'text' : 'password'}
                                            id='adornment-password'
                                            name='password'
                                            label={text.label_password}
                                            placeholder={text.label_password}
                                            onChange={(e) => this.handleChange(e,'password')}
                                            value={this.state.password}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        <IconButton
                                                            aria-label='Toggle password visibility'
                                                            onClick={this.handleClickShowPassword}
                                                            className='pass'
                                                        >
                                                            {this.state.showPassword ? <i className='fas fa-eye'> </i> :
                                                                <i className='fas fa-eye-slash'> </i>}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                            fullWidth
                                        />
                                        {this.validator.message('password', this.state.password, 'required')}
                                        <div className='text-danger'>{this.state.errors.password}</div>
                                    </div>
                                    <div className='form-group'>
                                        <TextField
                                            type={this.state.showPasswordConfirm ? 'text' : 'password'}
                                            id='adornment-password'
                                            name='passwordConfirm'
                                            label={text.label_password_confirm}
                                            placeholder={text.label_password_confirm}
                                            onChange={(e) => this.handleChange(e,'passwordConfirm')}
                                            value={this.state.passwordConfirm}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        <IconButton
                                                            aria-label='Toggle password visibility'
                                                            onClick={this.handleClickShowPasswordConfirm}
                                                            className='pass'
                                                        >
                                                            {this.state.showPasswordConfirm ? <i className='fas fa-eye'> </i> :
                                                                <i className='fas fa-eye-slash'> </i>}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                            fullWidth
                                        />
                                        {this.validator.message('passwordConfirm', this.state.passwordConfirm, 'required')}
                                        <div className='text-danger'>{this.state.errors.passwordConfirm}</div>
                                    </div>

                                    <div className='row align-items-center mt-3'>
                                        <div className='col-6 text-left'>
                                        </div>
                                        <div className='col-6'>
                                            <Button
                                                variant='contained'
                                                onClick={this.handleSubmit}
                                                color='primary'
                                                disabled={this.state.loading}
                                                className='full'
                                            >
                                                {text.button_register} { this.state.loading && <i className='fa fa-spinner fa-spin'> </i> }
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className='col-12 mt-auto footer-float'>
                                <div className='row justify-content-end'>
                                    <div className='col-md-6 col-lg-5'>
                                        <footer>
                                            <p className='text-center d-none d-md-block'> <Copyright /></p>
                                        </footer>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                <footer>
                    <p className='text-center d-md-none'> <Copyright /></p>
                </footer>

                <ToastContainer autoClose={false} />
            </main>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        profile_state: state.profile_state,

        toggle_set_lang: state.toggle_set_lang,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
