import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import templateId from '../../../../libraries/lang-id';
import languange from '../../../../libraries/label-lang';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Logo from '../../../../images/logo-uai-ypi-big-300x97.png';
import {history} from '../../../../shared/configure-store';
import {toast, ToastContainer} from 'react-toastify';
import Copyright from '../../../presentational/copyright';
import _ from 'lodash';
import AuthHelper from '../../../../libraries/auth-helper';
import Api from '../../../../libraries/api';
import {bindActionCreators} from 'redux';

import {connect} from 'react-redux';
import {RootActions} from '../../../../shared/root-action';

require('dotenv').config();

SimpleReactValidator.addLocale('indo', templateId);

class Login extends Component {

    constructor(props) {

        super(props);

        let lang = AuthHelper.getLang();

        this.validator = new SimpleReactValidator(
            {
                locale: lang
            }
        );

        this.state = {
            loading: false,
            showPassword: false,

            errors: [],
            username: '',
            password: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChecked = this.handleChecked.bind(this);
        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        document.title = 'PSIKOTES ONLINE - Login';
    }

    componentWillReceiveProps() {

        let lang = AuthHelper.getLang();

        this.validator = new SimpleReactValidator(
            {
                locale: lang
            }
        );
    }

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        })

    };

    handleChecked = name => e => {

        this.setState({

            [name]: e.target.checked ? 1 : 0,

        })

    };

    handleClickShowPassword = () => {

        this.setState({

            showPassword:(!this.state.showPassword)

        });
    };

    handleSubmit = e => {
        e.preventDefault();

        if (!this.validator.allValid()) {

            this.setState({
                    errors: this.validator.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loading: true,
            }
        );

        let params = {
            username: this.state.username,
            password: this.state.password,
        };

        Api.post('/authenticate', params).then(resp => {


            // let params2 = {
            //     id:resp.id,
            //     pagination:{
            //         limit: 1,
            //         page: 1
            //     },
            //     relation:[
            //         'jabatan',
            //         'provinsi'
            //     ]
            // };

            // Api.post('/user',params2).then(resd=>{
            // })
            if(resp.access) {
                AuthHelper.setLogin(resp);

                AuthHelper.setProfile(resp);
                //
                // let profile = resp;
                //
                // let user = JSON.parse(JSON.stringify(profile));
                // if(user.name) {
                //     user.akses = user.name.split('-')[1];
                //     user.name = user.name.split('-')[0];
                // }
                // this.props.setProfile(user);
                // AuthHelper.setProfile(user);
                //
                history.push('/');
            }
            else{
                this.showMessage(false, 'Your can`t be access,\n please confirm to admin!');//'Username or password
                // is not
                // valid!');
            }
            this.setState({
                    loading: false
                }
            );

        }).catch(err => {
            let errors = [];
            if(err.errors) {
                errors = err.errors;
                _.forEach(errors, function(v,k){
                    v = v[0];
                    if(v === 'The selected user is invalid.') {
                        errors = [];
                        err.message = 'Invalid user and password.'
                    }
                });
            }

            this.setState({
                    errors: errors,
                    loading: false
                }
            );

            this.showMessage(false, err.message);//'Username or password is not valid!');
        });

    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    render() {

        let lang = {};
        if(this.props.toggle_set_lang === 'indo') {
            lang = languange.id;
        }else {
            lang = languange.en;
        }
        const text = lang;

        return (
            <main className='login'>
                <section>
                    <div className='container position-relative'>
                        <div className='row align-items-end m-0 row-login'>
                            {/*<div className='' style={{'top': '26px', 'right': '0px', 'position': 'absolute'}}>*/}
                            {/*    <div className=''>*/}
                            {/*        <img src={logo1} alt='logo' className='img-logo-footer' style={{'width': '100px', 'background': 'black', 'borderRadius':'10px'}} />*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <div className='col-md-6 col-lg-7 px-lg-7 text-center text-md-left'>
                                <img src={Logo} alt='logo' className='img-logo' />
                                {/* <h1 className='title'>PSIKOTES ONLINE</h1> */}
                                {/* <h1 className='sub-title'>Psikotes</h1> */}
                            </div>

                            <div className='col-md-6 col-lg-5 px-lg-5 text-center'>
                                <h1>{text.login_heading}</h1>
                                <p>{text.login_welcome},<br />
                                    {text.login_paragraph}</p>
                                <form name='login' id='login' noValidate>
                                    <div className='form-group'>
                                        <TextField
                                            type='text'
                                            id='username'
                                            name='username'
                                            label={text.label_username}
                                            placeholder={text.label_username}
                                            onChange={(e) => this.handleChange(e, 'username')}
                                            value={this.state.username}
                                            fullWidth
                                        />
                                        {this.validator.message('username', this.state.username, 'required')}
                                        <div className='text-danger'>{this.state.errors.username && this.state.errors.username}</div>
                                    </div>
                                    <div className='form-group'>
                                        <TextField
                                                   type={this.state.showPassword ? 'text' : 'password'}
                                                   id='adornment-password'
                                                   name='password'
                                                   label={text.label_password}
                                                   placeholder={text.label_password}
                                                   onChange={(e) => this.handleChange(e,'password')}
                                                   value={this.state.password}
                                                   className='pass'
                                                   InputProps={{
                                                       endAdornment: (
                                                           <InputAdornment position='end'>
                                                               <IconButton
                                                                   aria-label='Toggle password visibility'
                                                                   onClick={this.handleClickShowPassword}
                                                                   className='pass'

                                                               >
                                                                   {this.state.showPassword ? <i className='fas fa-eye'> </i> :
                                                                       <i className='fas fa-eye-slash'> </i>}
                                                               </IconButton>
                                                           </InputAdornment>
                                                       )
                                                   }}
                                                   fullWidth
                                        />
                                        {this.validator.message('password', this.state.password, 'required')}
                                        <div className='text-danger'>{this.state.errors.password && this.state.errors.password}</div>
                                    </div>
                                    <div className='row align-items-center'>
                                        {/*<div className='col-12 text-right mb-3'>*/}
                                        {/*    <Link to='/forgot' className='text-blue'>{text.login_forgot}?</Link>*/}
                                        {/*</div>*/}
                                        <div className='col-6 text-left'>
                                            <Link to='/register' className='text-blue'>{text.login_register}</Link>
                                        </div>
                                        <div className='col-6'>
                                            <Button
                                                variant='contained'
                                                onClick={this.handleSubmit}
                                                color='primary'
                                                disabled={this.state.loading}
                                                className='full'
                                                type='submit'
                                            >
                                                {text.button_login} { this.state.loading && <i className='fa fa-spinner fa-spin'> </i> }
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div className='col-12 mt-auto footer-float'>
                                <div className='row justify-content-end'>
                                    <div className='col-12'>
                                    </div>
                                    <div className='col-md-6 col-lg-5'>
                                        <footer>
                                            <p className='text-center d-none d-md-block'>
                                                <Copyright />
                                            </p>
                                        </footer>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                <footer>
                    <p className='text-center d-md-none'><Copyright /></p>
                </footer>
                <ToastContainer autoClose={false} />
            </main>
        )

    }
}

const mapStateToProps = (state) => {

    return {

        profile_state: state.profile_state,

        toggle_set_lang: state.toggle_set_lang,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
