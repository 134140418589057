import React,{Component} from 'react';

require('dotenv').config();

class Copyright extends Component {
    render() {
        return (
            <span>Copyright © 2021 PSIKOTES ONLINE. All rights reserved</span>
        )
    }
}

export default Copyright;