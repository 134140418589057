import React,{Component} from 'react';
import Button from '@material-ui/core/Button';
import {history} from '../../../../shared/configure-store';
import {connect} from 'react-redux';
import {RootActions} from '../../../../shared/root-action';
import {toast, ToastContainer} from 'react-toastify';
import {bindActionCreators} from 'redux';
import Api from '../../../../libraries/api';
import languange from "../../../../libraries/label-lang";
import {Link} from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import AuthHelper from "../../../../libraries/auth-helper";
import SimpleReactValidator from "simple-react-validator";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import Switch from "@material-ui/core/Switch";

require('dotenv').config();

class CategoryDetail extends Component {
    constructor(props) {

        super(props);

        let lang = AuthHelper.getLang();

        this.validator = new SimpleReactValidator(
            {
                locale: lang
            }
        );

        this.state = {
            // loading: false,
            loadingButton: false,
            showButton: false,

            errors: [],
            listCategory:[],
            listView:[
                {
                    name: '--- Subcategories ---',
                    id: ''
                },
                {
                    name: 'Table',
                    id: 'Table'
                },
                {
                    name: 'Multiple Choice',
                    id: 'Multiple Choice'
                },
                {
                    name: 'Image',
                    id: 'Image'
                },
                {
                    name: 'Kraepelin',
                    id: 'Kraepelin'
                },
            ],
            listSubcategory:[],
            _tempListSubcategory:[{
                name: '--- Subcategories ---',
                id: ''
            }],

            id: '',
            name: '',
            categoryId:'',
            subcategoryId:'',
            descriptions: '',
            duration:'',
            active:false,
            view:'',

            disableUpdate: true,

        }
    }

    componentDidMount() {
        document.title = 'PSIKOTES ONLINE - Detail';
        const id = Number(this.props.match.params.id);

        this.setState({
            loadingButton: true,
            id: id,
        });

        Api.post('/categories',{}).then(res => {
            let dataCategory = [{
                name: '--- Categories ---',
                id: ''
            }];
            let response = res.result.data;

            response.map(v => {
                if (v.id) {
                    dataCategory.push(v);
                }
                return v;
            })
            this.setState({
                listCategory: dataCategory,
                categoryId: ''
            });

            this._loadData(id);

        }).catch(err=>{
            this.showMessage(false, err.message);
            this.setState({
                loadingButton: false,
            })
        });

        Api.post('/subcategories',{}).then(res => {
            let dataSubcategory = [{
                name: '--- Subcategories ---',
                id: ''
            }];
            let response = res.result.data;

            response.map(v => {
                if (v.id) {
                    dataSubcategory.push(v);
                }
                return v;
            })
            this.setState({
                listSubcategory: dataSubcategory,
                loadingButton: false,
            });
            this._loadData(id);
        }).catch(err=>{
            this.showMessage(false, err.message);
            this.setState({
                loadingButton: false,
            })
        });
    };

    _loadData(id){
        Api.get('/theories/'+id).then(resp => {
            let response = resp.result.data;
            let setData = {};

            Object.keys(response).map(k =>{
                setData[k]= response[k];
                if(k === 'categoryId'){
                    this.__reloadSubcategory(response[k]);
                }
                return k;
            });
            this.setState({
                loadingButton: false,
                ...setData
            },()=>{
            });
        }).catch(err => {
            this.setState({
                loading: false,
            });
            this.showMessage(false, err.message);
        });
    }


    UNSAFE_componentWillReceiveProps() {

        let lang = AuthHelper.getLang();

        this.validator = new SimpleReactValidator(
            {
                locale: lang
            }
        );
    }

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        },()=>{
            if(prop === 'categoryId'){
                this.__reloadSubcategory(e.target.value);
            }
        });
    };

    __reloadSubcategory(id){
        let _tmp = [{
            name: '--- Subcategories ---',
            id: ''
        }];
        this.state.listSubcategory.map(v=>{
            if(v.categoryId === id){
                _tmp.push(v);
            }
            return v;
        });
        this.setState({
            _tempListSubcategory: _tmp
        });
    }

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    enableUpdate = ()=>{
      this.setState({
          disableUpdate: false
      })
    };

    handleGo  = (link) => {

        history.push(link);
    };

    handleChecked = name => e => {
        this.setState({
            [name]: e.target.checked ? true : false,
        })
    };

    handleSubmit = (e) => {

        e.preventDefault();

        if (!this.validator.allValid()) {

            this.setState({
                    errors: this.validator.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true
            }
        );

        let params = {
            name: this.state.name,
            categoryId: this.state.categoryId,
            descriptions: this.state.descriptions,
            subcategoryId: this.state.subcategoryId,
            duration:  this.state.duration,
            active:  this.state.active,
            view:  this.state.view,
        };

        Api.put('/theories/'+this.state.id,params).then(resp => {

            this.setState({
                loadingButton: false,
            });

            this.handleGo('/master_theory');
            this.showMessage(true, 'Category successfully updated!');

        }).catch(err => {

            let errors = {};
            Object.keys(err).map((item) => {
                if(item){
                    errors[item] = err[item][0] ? err[item][0] : err[item];
                }
                return item;
            });

            this.setState({
                    errors: errors ? errors : {},
                    loadingButton: false
                }
            );

            this.showMessage(false, err.message);

        });


    };

    render() {

        let lang = {};
        if(this.props.toggle_set_lang === 'indo') {
            lang = languange.id;
        }else {
            lang = languange.en;
        }
        const text = lang;

        return (
            <div className='dashboard-detail'>
                <nav aria-label='breadcrumb'>
                    <ol className='breadcrumb'>
                        <li className='breadcrumb-item'><Link to='/' >{text.label_dashboard}</Link></li>
                        <li className='breadcrumb-item'><Link to='/master_theory' >{text.label_master_theory}</Link></li>
                        <li className='breadcrumb-item active' aria-current='page'>{this.state.disableUpdate ? text.label_detail : text.button_edit}</li>
                    </ol>
                </nav>

                <div className='row'>
                    {/*<div className='col-lg-8 col-xl-7'>*/}
                    <div className='col-lg-12 col-xl-12'>
                        <div className='card-white'>
                            <h2 className='text-uppercase'>{this.state.disableUpdate ? text.label_detail : text.button_edit} {text.label_theory}</h2>
                            <form name='add' id='addProduct' className='row' noValidate>
                                <div className='col-12 mb-2'>
                                    <div className='row'>
                                        <div className='col-md-6 col-lg-6 col-sm-12'>
                                            <div className='form-group'>
                                                <TextField
                                                    type='text'
                                                    id='name'
                                                    name='name'
                                                    label={text.label_theory_name}
                                                    //placeholder={text.label_theory_name}
                                                    onChange={(e) => this.handleChange(e, 'name')}
                                                    value={this.state.name}
                                                    disabled={this.state.disableUpdate}
                                                    fullWidth
                                                />
                                                {this.validator.message('name', this.state.name, 'required')}
                                                <div className='text-danger'>{this.state.errors.name}</div>
                                            </div>
                                            <div className='form-group'>
                                                <TextField
                                                    type='text'
                                                    id='descriptions'
                                                    name='descriptions'
                                                    label={text.label_theory_descriptions}
                                                    //placeholder={text.label_theory_descriptions}
                                                    onChange={(e) => this.handleChange(e, 'descriptions')}
                                                    value={this.state.descriptions}
                                                    fullWidth
                                                    disabled={this.state.disableUpdate}
                                                    multiline
                                                    rows={4}
                                                />
                                                {/*{this.validator.message('descriptions', this.state.descriptions, 'required')}*/}
                                                <div className='text-danger'>{this.state.errors.descriptions}</div>
                                            </div>
                                            <div className='form-group'>
                                                <TextField
                                                    select
                                                    id='categoryId'
                                                    name='categoryId'
                                                    label={text.label_category}
                                                    onChange={(e) => this.handleChange(e, 'categoryId')}
                                                    value={this.state.categoryId}
                                                    fullWidth
                                                    disabled={this.state.disableUpdate}
                                                >
                                                    {this.state.listCategory.map((data, idl) => (
                                                        <MenuItem key={idl} value={data.id}>
                                                            {data.name}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                                {this.validator.message('categoryId', this.state.categoryId, 'required')}
                                                <div className='text-danger'>{this.state.errors.categoryId}</div>
                                            </div>
                                            <div className='form-group'>
                                                <TextField
                                                    select
                                                    id='subcategoryId'
                                                    name='subcategoryId'
                                                    label={text.label_subcategory}
                                                    onChange={(e) => this.handleChange(e, 'subcategoryId')}
                                                    value={this.state.subcategoryId}
                                                    fullWidth
                                                    disabled={this.state.disableUpdate}
                                                >
                                                    {this.state._tempListSubcategory.map((data, idl) => (
                                                        <MenuItem key={idl} value={data.id}>
                                                            {data.name}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                                {this.validator.message('subcategoryId', this.state.subcategoryId, 'required')}
                                                <div className='text-danger'>{this.state.errors.subcategoryId}</div>
                                            </div>
                                        </div>
                                        <div className='col-md-6 col-lg-6 col-sm-12'>
                                            <div className='row'>
                                                <div className='form-group col-8'>
                                                    <TextField
                                                        type='text'
                                                        id='duration'
                                                        name='duration'
                                                        label={text.label_duration+' ('+text.label_minute+')'}
                                                        //placeholder={text.label_duration}
                                                        onChange={(e) => this.handleChange(e, 'duration')}
                                                        value={this.state.duration}
                                                        fullWidth
                                                        disabled={this.state.disableUpdate}
                                                    />
                                                    {this.validator.message('duration', this.state.duration, 'required')}
                                                    <div className='text-danger'>{this.state.errors.duration}</div>
                                                </div>
                                                <div className='form-group col-4'>
                                                    <FormControl component='fieldset' className='col-12'>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <AntSwitch
                                                                        checked={this.state.active}
                                                                        onChange={this.handleChecked('active')}
                                                                        name="active"
                                                                    />
                                                                }
                                                                className=''
                                                                label={text.label_status+' '+ text.label_theory}
                                                                labelPlacement="top"
                                                                disabled={this.state.disableUpdate}
                                                            />
                                                            {this.validator.message('active', this.state.active, 'required')}
                                                            <div className='text-left text-alert'>{this.state.errors.active}</div>
                                                        </FormGroup>
                                                    </FormControl>
                                                </div>
                                            </div>
                                            <div className='form-group'>
                                                <TextField
                                                    select
                                                    id='view'
                                                    name='view'
                                                    label={text.label_view}
                                                    onChange={(e) => this.handleChange(e, 'view')}
                                                    value={this.state.view}
                                                    fullWidth
                                                    disabled={this.state.disableUpdate}
                                                >
                                                    {this.state.listView.map((data, idl) => (
                                                        <MenuItem key={idl} value={data.id}>
                                                            {data.name}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                                {this.validator.message('view', this.state.view, 'required')}
                                                <div className='text-danger'>{this.state.errors.view}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 text-center mt-4'>
                                    <Button
                                        variant='contained'
                                        className='mr-3'
                                        onClick={() => this.handleGo('/master_theory')}
                                    >
                                        {text.button_back}
                                    </Button>

                                    {this.state.disableUpdate ?
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            className='mr-3'
                                            onClick={this.enableUpdate}
                                            disabled={this.state.loadingButton}
                                        >
                                            {text.button_edit} {this.state.loadingButton &&
                                        <i className='fa fa-spinner fa-spin'> </i>}
                                        </Button>
                                        :
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            className='mr-3'
                                            onClick={this.handleSubmit}
                                            disabled={this.state.loadingButton}
                                        >
                                            {text.label_save} {this.state.loadingButton &&
                                        <i className='fa fa-spinner fa-spin'> </i>}
                                        </Button>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <ToastContainer />
            </div>
        );
    }
}


const mapStateToProps = (state) => {

    return {

        profile_state: state.profile_state,

        toggle_set_lang: state.toggle_set_lang,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryDetail);

const AntSwitch = withStyles((theme) => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: theme.palette.grey[500],
        '&$checked': {
            transform: 'translateX(12px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
    },
    checked: {},
}))(Switch);