import React,{Component} from 'react';
import SimpleReactValidator from 'simple-react-validator';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Copyright from '../../../presentational/copyright';
import {connect} from 'react-redux';
import {RootActions} from '../../../../shared/root-action';
import Logo from '../../../../images/logo-black.png';
import Image from '../../../../images/reset.png';
import {history} from '../../../../shared/configure-store';
import {toast, ToastContainer} from 'react-toastify';
import Api from "../../../../libraries/api";
import queryString from 'query-string';
import {bindActionCreators} from 'redux';

require('dotenv').config();

const validator = new SimpleReactValidator({locale: process.env.REACT_APP_LOCALE});

class ForgotReset extends Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            showPassword: false,
            showPasswordConfirm: false,

            errors: [],
            password: '',
            password_confirmation: '',
            email: '',
            token: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        document.title = 'PSIKOTES ONLINE - Reset Forgot';

        let url = this.props.location.search;
        let urlParams = queryString.parse(url);

        if(!url) {
            this.showMessage(false, 'Link is not valid');
            return false;
        }

        this.setState({
            email: urlParams.email,
            token: urlParams.token,
        });

    }

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        })

    };

    handleSubmit = e => {

        e.preventDefault();

        if (!validator.allValid()) {

            this.setState({
                    errors: validator.getErrorMessages()
                }
            );

            return false;

        }

        if (this.state.password !== this.state.password_confirmation) {

            this.setState({
                    errors: {
                        password: 'Kata sandi harus sama',
                        password_confirmation: 'Konfirmasi kata sandi harus sama',
                    }
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loading: true,
            }
        );

        let params = {

            email: this.state.email,
            token: this.state.token,
            password: this.state.password,
            password_confirmation: this.state.password_confirmation,

        };

        Api.post('/password/reset', params).then(resp => {

            this.showMessage(true, 'Password berhasil diubah');

            setInterval(function () {
                history.push('/login');
            }, 2000);

        }).catch(err => {

            this.setState({
                    errors: err,
                    loading: false
                }
            );

        });

    };

    handleClickShowPassword = () => {

        this.setState({

            showPassword:(!this.state.showPassword)

        });
    };

    handleClickShowPasswordConfirm = () => {

        this.setState({

            showPasswordConfirm:(!this.state.showPasswordConfirm)

        });
    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    render() {

        return (
            <main className='success'>
                <section className='my-auto'>
                    <div className='container text-center'>
                        <img src={Logo} alt='logo' className='img-logo' />
                        <img src={Image} alt='logo' className='d-block mx-auto mb-3' />
                        <p className='text-big text-blue'>Mengganti Kata Sandi</p>
                        <p className='text-center py-4 mb-0'>Masukkan kata sandi baru Anda di bawah ini</p>
                        <div className='row justify-content-center'>
                            <div className='col-md-6 col-lg-4'>
                                <form name='login' id='login' noValidate>
                                    <div className="form-group">
                                        <TextField
                                            type={this.state.showPassword ? 'text' : 'password'}
                                            id="adornment-password"
                                            name="password"
                                            label="Kata Sandi"
                                            placeholder="Kata Sandi"
                                            onChange={(e) => this.handleChange(e,'password')}
                                            value={this.state.password}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="Toggle password visibility"
                                                            onClick={this.handleClickShowPassword}
                                                            className="pass"
                                                        >
                                                            {this.state.showPassword ? <i className="fas fa-eye"> </i> :
                                                                <i className="fas fa-eye-slash"> </i>}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                            fullWidth
                                        />
                                        {validator.message('password', this.state.password, 'required')}
                                        <div className='text-danger'>{this.state.errors.password}</div>
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                            type={this.state.showPasswordConfirm ? 'text' : 'password'}
                                            id="adornment-password"
                                            name="password_confirmation"
                                            label="Konfirmasi Kata Sandi"
                                            placeholder="Konfirmasi Kata Sandi"
                                            onChange={(e) => this.handleChange(e,'password_confirmation')}
                                            value={this.state.password_confirmation}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="Toggle password visibility"
                                                            onClick={this.handleClickShowPasswordConfirm}
                                                            className="pass"
                                                        >
                                                            {this.state.password_confirmation ? <i className="fas fa-eye"> </i> :
                                                                <i className="fas fa-eye-slash"> </i>}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                            fullWidth
                                        />
                                        {validator.message('password_confirmation', this.state.password_confirmation, 'required')}
                                        <div className='text-danger'>{this.state.errors.password_confirmation}</div>
                                    </div>
                                    <div className='row align-items-center justify-content-center mt-3'>
                                        <div className='col-6 text-center'>
                                            <Button
                                                variant='contained'
                                                onClick={this.handleSubmit}
                                                color='primary'
                                                disabled={this.state.loading}
                                                className='full'
                                                type='submit'
                                            >
                                                Reset { this.state.loading && <i className='fa fa-spinner fa-spin'> </i> }
                                            </Button>
                                        </div>
                                    </div>
                                    <div className='text-danger mb-2'>{this.state.errors.message}</div>
                                </form>

                            </div>
                        </div>
                    </div>
                </section>
                <footer>
                    <p className='text-center absolute-bottom'><Copyright /></p>
                </footer>

                <ToastContainer autoClose={false} />
            </main>
        )

    }
}

const mapStateToProps = (state) => {

    return {

        profile_state: state.profile_state,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotReset);