import React,{Component} from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SimpleReactValidator from 'simple-react-validator';
import {history} from '../../../../shared/configure-store';
import {toast, ToastContainer} from 'react-toastify';
import {Link, withRouter} from 'react-router-dom';
import ApiKoprasi from '../../../../libraries/apiKoprasi';
import {bindActionCreators} from "redux";
import {RootActions} from "../../../../shared/root-action";
import {connect} from "react-redux";
import languange from "../../../../libraries/label-lang";
import AuthHelper from "../../../../libraries/auth-helper";
import templateId from "../../../../libraries/lang-id";
import _ from 'lodash';
import NumberFormat from "react-number-format";
import Api from "../../../../libraries/api";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import withStyles from "@material-ui/core/styles/withStyles";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

SimpleReactValidator.addLocale('indo', templateId);


class QuestionsDetail extends Component {

    constructor(props) {

        super(props);


        let lang = AuthHelper.getLang();

        this.validator = new SimpleReactValidator(
            {
                locale: lang
            }
        );


        this.state = {
            // loading: false,
            loadingButton: false,

            errors: [],

            theoryId    : '',
            listTheory  : [
                { id: '', name:'--- Questions ---' },
            ],
            userId      : '',
            listUsers  : [
                { id: '', name:'--- Questions ---' },
            ],
            expire      : '',
            bod         : null,
            active      : false,
            descriptions: '',

            disableUpdate: true,

        }
    }

    componentDidMount(){

        document.title = 'PSIKOTES ONLINE - Add Category';

        const id = Number(this.props.match.params.id);
        const dataS = this.props?.location?.state ?? '';

        this.setState({
            loading: true,
            loadingButton: true,
            id: id,
        });

        if(dataS){
            let setData = {};
            Object.keys(dataS).map(k =>{
                setData[k]= dataS[k] ?? '';
                if(k === 'expire' && setData[k]){
                    setData['bod'] = new Date(setData[k]);
                }
                return k;
            });
            this.setState({
                ...setData,
            },()=>{
                this.__fetchData();
            });
        }
        else {
            this.__loadData(id);
        }

    };

    __fetchData(){

        this.setState({loadingButton:true});
        ApiKoprasi.post('/theories', {}).then(resp => {
            let response = resp?.result ?? {};

            this.setState({
                listTheory: response?.data ?? [],
                loadingButton: false
            });
        }).catch(e => {
            this.showMessage(false, e.message);
            this.setState({ loadingButton: false, })
        });
        ApiKoprasi.post('/users', {}).then(resp => {
            let response = resp?.result ?? {};

            this.setState({
                listUsers: response?.data ?? [],
                loadingButton: false
            });
        }).catch(e => {
            this.showMessage(false, e.message);
            this.setState({ loadingButton: false, })
        });
    };

    __loadData(id){

        this.setState({loadingButton:true});
        ApiKoprasi.post('/theories', {}).then(resp => {
            let response = resp?.result?.data ?? {};

            let setData = {};
            Object.keys(response).map(k =>{
                setData[k]= response[k];
                if(k === 'expire' && setData[k]){
                    setData['bod'] = new Date(setData[k]);
                }
                return k;
            });
            this.setState({
                ...setData,
            },()=>{
                this.__fetchData();
            });
        }).catch(e => {
            this.showMessage(false, e.message);
            this.setState({ loadingButton: false, })
        });
    };

    UNSAFE_componentWillReceiveProps() {

        let lang = AuthHelper.getLang();

        this.validator = new SimpleReactValidator(
            {
                locale: lang
            }
        );
    }

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        });

    };

    handleDate (date, prop){

        this.setState({

            [prop]: date

        })

    };

    handleChecked = name => e => {

        this.setState({

            [name]: e.target.checked ? true : false,

        })

    };

    handleGo  = (link) => {
        history.push(link);
    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    enableUpdate = ()=>{
        this.setState({
            disableUpdate: false
        })
    };

    handleUpload = e => {

        let temp = _.get(e,'target.files[0]',false);

        this.setState({
            errors: {file:''},
        });

        if(temp){
            if(e.target.files[0].size > 999999){

                this.setState({
                    errors: {
                        file: 'This file is too big. Maximum size is 1MB.'
                    },
                });

            }else if(e.target.files[0].name.toLowerCase().search('.jpeg') === -1 &&
                e.target.files[0].name.toLowerCase().search('.jpg') === -1  &&
                e.target.files[0].name.toLowerCase().search('.png') === -1){

                this.setState({
                    errors: {
                        file: 'This file is invalid. Only accept .jpg, .jpeg and .png'
                    },
                });

            }else{

                this.setState({
                    file: URL.createObjectURL(e.target.files[0]),
                    image_file: e.target.files[0],
                    image_name: e.target.files[0].name,
                });

            }
        }
    };

    handleSubmit = (e) => {

        e.preventDefault();

        if (!this.validator.allValid()) {

            this.setState({
                    errors: this.validator.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true
            }
        );

        let params = {
            theoryId    : this.state.theoryId,
            userId      : this.state.userId,
            expire      : this.state.bod !== '' ? this.state.bod.getFullYear() + '-' + appendLeadingZeroes(this.state.bod.getMonth() + 1) + '-' + appendLeadingZeroes(this.state.bod.getDate()): '',
            active      : this.state.active,
            descriptions: this.state.descriptions,
        };



        Api.put('/questions/'+this.state.id, params).then(resp => {
            this.setState({
                    loadingButton: false,
                }
            );
            this.setState({
                loading: false,
            });

            this.handleGo('/master_questions');

            this.showMessage(true, resp?.message ?? 'Test taker successfully updated');

        }).catch(err => {
            let errors = {};
            Object.keys(err).map((item) => {
                if(item){
                    errors[item] = err[item][0] ? err[item][0] : err[item];
                }
                return item;
            });
            this.setState({
                    errors: errors ? errors : {},
                    loadingButton: false
                }
            );
            this.showMessage(false, err.message);
        });


    };

    NumberFormatCustom = (props) => {
        const { inputRef, onChange, ...other } = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator
                isNumericString
            />
        );
    };

    render() {

        let lang = {};
        if(this.props.toggle_set_lang === 'indo') {
            lang = languange.id;
        }else {
            lang = languange.en;
        }
        const text = lang;

        return (

            <div className='dashboard-detail'>
                <nav aria-label='breadcrumb'>
                    <ol className='breadcrumb'>
                        <li className='breadcrumb-item'><Link to='/' >{text.label_dashboard}</Link></li>
                        <li className='breadcrumb-item'><Link to='/master_questions' >{text.label_master_questions}</Link></li>
                        <li className='breadcrumb-item active' aria-current='page'>{this.state.disableUpdate ? text.label_detail : text.button_edit}</li>
                    </ol>
                </nav>

                <div className='row'>
                    <div className='col-lg-8 col-xl-7'>
                        <div className='card-white'>
                            <h2 className='text-uppercase'>{this.state.disableUpdate ? text.label_detail : text.button_edit} {text.label_master_questions}</h2>
                            <form name='add' id='addQuestions' className='row' noValidate>
                                <div className='col-12 mb-2'>
                                    <div className='form-group'>
                                        <TextField
                                            select
                                            id='userId'
                                            name='userId'
                                            label={text.label_master_questions}
                                            onChange={(e) => this.handleChange(e, 'userId')}
                                            value={this.state.userId}
                                            fullWidth
                                            disabled={this.state.disableUpdate}
                                        >
                                            {this.state.listUsers.map((option, idx) => (
                                                <MenuItem key={idx} value={option.id}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        {this.validator.message('userId', this.state.userId, 'required')}
                                        <div className='text-danger'>{this.state.errors.userId}</div>
                                    </div>
                                    <div className='form-group'>
                                        <TextField
                                            select
                                            id='theoryId'
                                            name='theoryId'
                                            label={text.label_questions}
                                            onChange={(e) => this.handleChange(e, 'theoryId')}
                                            value={this.state.theoryId}
                                            fullWidth
                                            disabled={this.state.disableUpdate}
                                        >
                                            {this.state.listTheory.map((option, idx) => (
                                                <MenuItem key={idx} value={option.id}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        {this.validator.message('theoryId', this.state.theoryId, 'required')}
                                        <div className='text-danger'>{this.state.errors.theoryId}</div>
                                    </div>
                                    <div className='row'>
                                        <div className='form-group col-md-8'>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} className="col-md-12">
                                                <DatePicker
                                                    className='col-12'
                                                    label={text.label_questions_expire}
                                                    value={this.state.bod}
                                                    onChange={(date) => this.handleDate(date, 'bod')}
                                                    format={'yyyy-MM-dd'}
                                                    cancelLabel={text.button_back}
                                                    // inputVariant=""
                                                    size='small'
                                                    disabled={this.state.disableUpdate}
                                                />
                                            </MuiPickersUtilsProvider>
                                            {this.validator.message('bod', this.state.bod, 'required')}
                                            <div className='text-danger'>{this.state.errors.bod}</div>
                                        </div>
                                        <div className='form-group col-4'>
                                            <FormControl component='fieldset' className='col-12'>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <AntSwitch
                                                                checked={this.state.active}
                                                                onChange={this.handleChecked('active')}
                                                                name="active"
                                                            />
                                                        }
                                                        className=''
                                                        label={text.label_status+' '+text.label_questions}
                                                        labelPlacement="top"
                                                        disabled={this.state.disableUpdate}
                                                    />
                                                    {this.validator.message('active', this.state.active, 'required')}
                                                    <div className='text-left text-alert'>{this.state.errors.active}</div>
                                                </FormGroup>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className='form-group'>
                                        <TextField
                                            type='text'
                                            id='descriptions'
                                            name='descriptions'
                                            label={text.note_heading}
                                            onChange={(e) => this.handleChange(e, 'descriptions')}
                                            value={this.state.descriptions}
                                            fullWidth
                                            multiline
                                            rows={5}
                                            disabled={this.state.disableUpdate}
                                        />
                                    </div>

                                </div>
                                <div className='col-12 text-center mt-4'>
                                    <Button
                                        variant='contained'
                                        className='mr-3'
                                        onClick={() => this.handleGo('/master_questions')}
                                    >
                                        {text.button_back}
                                    </Button>
                                    {this.state.disableUpdate ?
                                        (<Button
                                            variant='contained'
                                            color='primary'
                                            className='mr-3'
                                            onClick={this.enableUpdate}
                                            disabled={this.state.loadingButton}
                                        >
                                            {text.button_edit} {this.state.loadingButton &&
                                        <i className='fa fa-spinner fa-spin'> </i>}
                                        </Button>)
                                        :
                                        (<Button
                                            variant='contained'
                                            color='primary'
                                            className='mr-3'
                                            onClick={this.handleSubmit}
                                            disabled={this.state.loadingButton}
                                        >
                                            {text.label_save} {this.state.loadingButton &&
                                        <i className='fa fa-spinner fa-spin'> </i>}
                                        </Button>)
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <ToastContainer />
            </div>

        )

    }
}

const mapStateToProps = (state) => {

    return {

        toggle_set_lang: state.toggle_set_lang,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

const AntSwitch = withStyles((theme) => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: theme.palette.grey[500],
        '&$checked': {
            transform: 'translateX(12px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
    },
    checked: {},
}))(Switch);

function appendLeadingZeroes(n) {
    if (n <= 9) {
        return "0" + n;
    }
    return n
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(QuestionsDetail));