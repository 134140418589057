import React,{Component} from 'react';
import SimpleReactValidator from 'simple-react-validator';
import {history} from '../../../../shared/configure-store';
import {toast, ToastContainer} from 'react-toastify';
import {Link, withRouter} from 'react-router-dom';
import {bindActionCreators} from "redux";
import {RootActions} from "../../../../shared/root-action";
import {connect} from "react-redux";
import languange from "../../../../libraries/label-lang";
import AuthHelper from "../../../../libraries/auth-helper";
import templateId from "../../../../libraries/lang-id";
import _ from 'lodash';
import NumberFormat from "react-number-format";
import Api from "../../../../libraries/api";
import moment from "moment";
import {MultipleChoice} from '../lib_questions/multipleChoice';

SimpleReactValidator.addLocale('indo', templateId);


class Index extends Component {

    constructor(props) {

        super(props);

        let lang = AuthHelper.getLang();

        this.validator = new SimpleReactValidator(
            {
                locale: lang
            }
        );

        this.state = {
            loading: true,
            loadingButton: true,
            startedTime: true,

            // data schedules
            active: false,
            answerId: "",
            bod: null,
            descriptions: "",
            expire: "",
            id: '',
            theory: {},
            theoryId: '',
            user: {},
            age:0,

            // data questions
            rows:[],
            sumQuestion: 0,

            // timer

            timeout: '',
            countdown: false,
            time: '',

            answers:[],

            hours: 1,
            minutes: 59,
            seconds: 59,

            answersToPut:{},

            startTest: '',
            endTest: '',
            nextToSave: false,

            kreapelin:{
                q:{},
                a:{}
            },

            kraepelinSession: 0,
            sessionsTimer: [],

            counterTimeKrp:0

        }
        this.handleAnswer = this.handleAnswer.bind(this);
        this.__startTimer = this.__startTimer.bind(this);
    }

    componentDidMount(){

        document.title = 'PSIKOTES ONLINE - Add Category';

        //00-0 1
        //000- 10
        //0-00 3

        const id = this.props.match.params.id;
        const dataS = this.props?.location?.state ?? '';

        this.setState({
            loading: true,
            loadingButton: true,
            startedTime: true,
            minutes: 59,
            seconds: 59,
        })



        let toCheck = dataS ? ('00-0'+dataS.id+'000-'+dataS.theoryId+'0-00'+dataS.userId) : '-';

        if(dataS && id && id === toCheck){
            let setData = {};
            Object.keys(dataS).map(k =>{
                setData[k]= dataS[k] ?? '';
                if(k === 'expire' && setData[k]){
                    setData['bod'] = new Date(setData[k]);
                }
                return k;
            });
            this.setState({
                ...setData,
            },()=>{
                this.__loadData(setData.theoryId)
            });
        }
        else{
            this.handleGo('/schedules');
            this.showMessage(false,'You don`t have access this testing!')
        }

    };

    __loadData(id){
        const params = {
            ...(id ? { theoryId : id } : {}),
        };
        Api.post('/questions_detail',params).then(resp => {
            let response = resp?.result ?? {};

            if(response.data.length){
                // response.data.map(v=>{
                //     if(v.question){
                //         v.question = v.question.split('\n').join('<br/>');
                //     }
                //     if(v.answers && (v.type === 'radio' || v.type === 'checkbox')){
                //         v.answers = JSON.parse(v.answers);
                //     }
                // })
                response.data = this._generateDataQuestion(response.data);
            }


            this.setState({
                loading: false,
                loadingButton: false,
                startedTime: false,
                rows: response?.data ?? [],
                sumQuestion: response?.data ? response?.data.length : 0,
                countQuestion: response?.pagination?.totalItems ? response?.pagination?.totalItems : 0,
                // paginations
                perPage: response?.pagination && response.pagination.size ? response.pagination.size : this.state.perPage,
                currentPage: response?.pagination && response?.pagination.currentPage ? response?.pagination.currentPage -1 : this.state.currentPage,
                currentPageTable: response?.pagination && response?.pagination.currentPage ? response?.pagination.currentPage -1 : this.state.currentPageTable,
                total: response?.pagination && response?.pagination.totalItems ? response?.pagination.totalItems : this.state.total,
            });
        }).catch(err => {

            this.setState({
                loading: false,
            });
            this.showMessage(false, err.message);
        });
    };

    __conditionKraepelin = (x,y) =>{
        if(x && y) {

            let t = x * y;

            let tt = t - x;

            let y1 = 0;

            for (y1 = 1; y1 <= y; y1++) {

                this.__generateDataKraepelin(x, y1);

            }


            // let soal = this.__showKraepelin( x, y, t, tt,'soal');

            let jawab = this.__showKraepelin( x, y, t, tt,'jawab');

            return jawab;
        }
    }

    __generateDataKraepelin = (x ,y1) =>{
        let v1 = x * y1 - x;
        let data = "";
        let x1 = 0;

        let gData = this.state.kreapelin;

        let check = [];
        for(x1 = 1; x1<= x; x1++){

            let v = "v"+( v1+ x1);

            let n = Math.floor(Math.random() * 9);
            gData.q[v] = n;

            check.push(n)

        }
        this.setState({
            kreapelin: gData
        },()=>{
        })
        return  data;
    }

    __showKraepelin = ( x, y, t, tt, p) =>{

        let data = "<table>";
        let y1 = 0;

        for( y1=1; y1<= y; y1++){
            if( y1>1 ){

                data+="<tr class='jwb'>";

                data+=this.__deretJawabX( x, y1, p);

                data+="</tr>";

            }

            data+="<tr>";

            data+=this.__deretSoalX( x, y1);

            data+="</tr>";

        }

        data+="</table>";

        return data;

    }



    __deretSoalX = ( x, y1) =>{

        let data="";

        let xx = x* y1- x;

        let x1 = 0;

        for( x1=1; x1<= x; x1++){

             let v = "v"+( xx+ x1);

             data = data+"<td>"+ this.state.kreapelin[v]+"</td><td> </td>";

        }

        return  data;

    }

    __deretJawabX = ( x, y1, p) =>{

        let data ="<td> </td>";

        let xx = x* y1- x;

        let xx1 = x* y1- x- x;

        let x1 = 0;

        let gData = this.state.kreapelin;

        for( x1 = 1; x1<= x; x1++){

            if( p==='jawab'){

                 let v = "v"+( xx+ x1);

                 let v1 = "v"+( xx1+ x1);

                 // let j = substr( this.state.kreapelin[v]+ this.state.kreapelin[v1],-1);
                 let j = this.state.kreapelin.q[v]+this.state.kreapelin.q[v1];
                 // j = j.toString().substring(-1);
                 j = j.toString().substring(-1);

                 gData.a[v1] = j;

                 data+="<td>"+j+"</td><td> </td>";

            }

            else{

                 data+="<td> </td><td> </td>";

            }

        }
        this.setState({
            kreapelin: gData
        })

        // let __return = data.substring(0,-15);
        return data;

    }

    _generateDataQuestion(questions_detail){
        // kraepelin
        // this.__conditionKraepelin(10,1)
        // ----------------------------------

        let tosetTimer = [];
        if(questions_detail.length){
            let nData = [];
            questions_detail.map((v,k)=>{
                let dataAns = [];
                v.locked = this.state.theory?.categoryId === 5 && this.state.theory?.subcategoryId === 13 ? true : false;
                if(v.question){
                    v.question = JSON.parse(v.question);
                    if(v.question.length) {
                        v.question.map(nv=>{
                            nv.locked = this.state.theory?.categoryId === 5 && this.state.theory?.subcategoryId === 13 ? true : false;
                            if (this.state.theory.view  === 'Kraepelin'){
                                nv.question = nv.question.split('\n').join('');
                                nv.question = nv.question.split(',');
                                dataAns.push(nv.question);
                            }
                            else {
                                nv.question = nv.question.split('\n').join('<br/>');
                            }
                            return nv;
                        })
                    }
                }
                if(v.images){
                    v.images = JSON.parse(v.images);
                }
                if(v.answers && (v.type === 'radio' || v.type === 'checkbox')){
                    v.answers = JSON.parse(v.answers);
                    if(v.answers.length){
                        v.answers.map((v1,k1)=>{
                            v1.selected = false;
                            v1.userId = this.state.userId;
                            v1.theoryId = this.state.theoryId;
                            v1.categoryId = this.state.theory.categoryId;
                            v1.subcategoryId = this.state.theory.subcategoryId;
                            v1.questionDetailId = v.id;
                            v1.questionDetailCode = v.code;
                            return v1;
                        });
                    }
                    else{
                        v.answers = [{
                            selected: false,
                            userId: this.state.userId,
                            theoryId: this.state.theoryId,
                            questionDetailId: v.id,
                            questionDetailCode: v.code,
                            categoryId : this.state.theory.categoryId,
                            subcategoryId : this.state.theory.subcategoryId,
                            code:0,
                            label:"",
                            answer:"",
                            images:[]
                        }];
                    }
                }
                else if(dataAns.length){
                    v.answers = JSON.parse(v.answers);

                    dataAns.map((snN, kSnN)=>{
                        // tosetTimer.push((kSnN+1) * 30);
                        // tosetTimer.push((kSnN+1) * 2);
                        if(kSnN < dataAns.length-1) {
                            let kNL = [];
                            snN.map((snNx, ksnNx) => {

                                if(kSnN === 0){
                                    tosetTimer.push((ksnNx+1) * 30);
                                }

                                if (kNL.length) {
                                    kNL.push({
                                        selected: false,
                                        userId: this.state.userId,
                                        theoryId: this.state.theoryId,
                                        questionDetailId: v.id,
                                        questionDetailCode: v.code,
                                        categoryId : this.state.theory.categoryId,
                                        subcategoryId : this.state.theory.subcategoryId,
                                        answer: "",
                                        code: 'Q-k-' + kSnN + '-' + ksnNx,
                                        label: 'Q-k-' + kSnN + '-' + ksnNx,
                                        session: ksnNx,
                                        timer:30
                                    });
                                } else {
                                    kNL = [{
                                        selected: false,
                                        userId: this.state.userId,
                                        theoryId: this.state.theoryId,
                                        questionDetailId: v.id,
                                        questionDetailCode: v.code,
                                        categoryId : this.state.theory.categoryId,
                                        subcategoryId : this.state.theory.subcategoryId,
                                        answer: "",
                                        code: 'Q-k-' + kSnN + '-' + ksnNx,
                                        label: 'Q-k-' + kSnN + '-' + ksnNx,
                                        session: ksnNx,
                                        timer:30
                                    }];
                                }
                            })
                            if (v.answers.length) {
                                v.answers.push(kNL);
                            } else {
                                v.answers = [kNL];
                            }
                        }
                        return snN;
                    })

                    // this.setState({
                    //     sessionsTimer:tosetTimer
                    // });
                }
                else{
                    v.answers = [{
                        selected: false,
                        userId: this.state.userId,
                        theoryId: this.state.theoryId,
                        questionDetailId: v.id,
                        questionDetailCode: v.code,
                        categoryId : this.state.theory.categoryId,
                        subcategoryId : this.state.theory.subcategoryId,
                        code:0,
                        label:"",
                        answer:"",
                        images:[]
                    }];

                }

                if(v.example) {
                    tosetTimer.push((k+1)*180);
                }

                nData.push(v);
                return v;
            });

            this.setState({
                sessionsTimer:tosetTimer
            });

            return questions_detail;
        }
        else{
            return questions_detail;
        }
    }

    UNSAFE_componentWillReceiveProps() {

        let lang = AuthHelper.getLang();

        this.validator = new SimpleReactValidator(
            {
                locale: lang
            }
        );
    }

    componentWillUnmount() {
        clearInterval(this.myInterval)
    }

    // timer
    __startTimer = () => {
        clearInterval(this.myInterval);

        this.setState({
            hours: 0,
            minutes: this.state?.theory?.duration ?? 0 ,
            seconds: 0,
            countdown: true,
        }, () => {
            this.__timerFn();
        });
    };

    __timerFn = () =>{
        this.myInterval = setInterval(() => {
            const { seconds, minutes, counterTimeKrp } = this.state;

            if (seconds > 0) {
                this.setState(({ seconds }) => ({
                    seconds: seconds - 1,
                    counterTimeKrp: counterTimeKrp + 1
                }),()=>{
                    this.__changeSessionKraepelin({ currentQuestion:counterTimeKrp});
                })
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    this.__changeSessionKraepelin({ currentQuestion:counterTimeKrp});
                    clearInterval(this.myInterval);
                    this.__resetData();
                    return false;
                }
                else{
                    this.setState(({ minutes }) => ({
                        minutes: minutes - 1,
                        seconds: 59,
                        counterTimeKrp: counterTimeKrp + 1
                    }),()=>{
                        this.__changeSessionKraepelin({ currentQuestion:counterTimeKrp});
                    })
                }
            }


        }, 1000);

        this.setState({
            startTest: new Date()
        },()=>{
            if(this.state?.user?.role !== 1) {
                Api.put('/questions/answers/' + this.state.id, {
                    theoryId: this.state.theoryId,
                    answerId: JSON.stringify([]),
                    userId: this.state.userId,
                    active: false,
                }).then(resp => {
                    this.showMessage(true, 'Selamat Mengerjakan!');
                }).catch(err => {
                    this.showMessage(false, err.message);
                });
            }
            else{
                this.showMessage(true,'access by super admin to preview test drives');
            }
        });
    };

    __resetData = () => {
        this.setState({
            time: '',
            loading: true,
            loadingButton: true,
            endTest: new Date()
        },()=>{
            this.handleSubmit();
        });
    };


    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        });
        if(prop === 'harga' && e.target.value){
            this.setState({
                hargaJual: (Number(e.target.value) + (Number(e.target.value) * 20 /100))
            });
        }

    };

    handleChecked = name => e => {
        this.setState({

            [name]: e.target.checked ? true : false,

        })

    };

    handleGo  = (link) => {
        history.push(link);
    };

    showMessage = (status, message, closeIt = true) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: closeIt
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: closeIt
            });
        }
    };

    handleUpload = e => {

        let temp = _.get(e,'target.files[0]',false);

        this.setState({
            errors: {file:''},
        });

        if(temp){
            if(e.target.files[0].size > 999999){

                this.setState({
                    errors: {
                        file: 'This file is too big. Maximum size is 1MB.'
                    },
                });

            }else if(e.target.files[0].name.toLowerCase().search('.jpeg') === -1 &&
                e.target.files[0].name.toLowerCase().search('.jpg') === -1  &&
                e.target.files[0].name.toLowerCase().search('.png') === -1){

                this.setState({
                    errors: {
                        file: 'This file is invalid. Only accept .jpg, .jpeg and .png'
                    },
                });

            }else{

                this.setState({
                    file: URL.createObjectURL(e.target.files[0]),
                    image_file: e.target.files[0],
                    image_name: e.target.files[0].name,
                });

            }
        }
    };

    handleSubmit = (e) => {
        clearInterval(this.myInterval);

        this.setState({
            loading:true
        })

        let lang = {};
        if(this.props.toggle_set_lang === 'indo') {
            lang = languange.id;
        }else {
            lang = languange.en;
        }
        const text = lang;

        // this.handleGo('/schedules/'+this.state.id)

        let dataAnswer = [];
        let diffTime = this.state.endTest ? moment(this.state.endTest).diff(this.state.startTest, 'seconds', true)
            : moment().diff(this.state.startTest,'seconds',true);

        let __minute = Math.floor(diffTime / 60);
        let __second = diffTime - (__minute * 60);
        let __hours  = 0;
        let __hms = '';
        if(__minute > 60){
            __hours = Math.floor(__minute /60);
            __minute = __minute - (__hours * 60);
            __hms = __hours+' '+text.label_hour;
        }
        if(__minute){
            __hms = (__hms ? __hms+', ': '')+(__minute ? __minute+' '+text.label_minute : '');
        }
        if(__second){
            __hms = (__hms ? __hms+', ': '')+(__second ? __second+' '+text.label_second : '');
        }


        let __expire = this.state.bod !== '' ? this.state.bod.getFullYear() + '-' + appendLeadingZeroes(this.state.bod.getMonth() + 1) + '-' + appendLeadingZeroes(this.state.bod.getDate()): '';


        if(this.state.theory.view  === 'Kraepelin'){
            const { rows } = this.state;

            if(rows && rows.length){
                rows.map((v,k)=>{
                    dataAnswer.push({
                        userId: this.state.userId,
                        theoryId: this.state.theoryId,
                        questionId: k,
                        answer: JSON.stringify(v.answers),
                        active: true,
                        duration: __hms,
                        expire: __expire,
                        categoryId : this.state.theory.categoryId,
                        subcategoryId : this.state.theory.subcategoryId,
                        sumAnswer: '',
                        score: '',
                    });
                    return v;
                })
            }

        }
        else if(Object.keys(this.state.answersToPut).length){
            let __tmpAnswer = this.state.answersToPut;

            Object.keys(__tmpAnswer).map(k=>{
                dataAnswer.push({
                    userId: this.state.userId,//__tmpAnswer[k]['userId'],
                    theoryId: this.state.theoryId,//__tmpAnswer[k]['theoryId'],
                    questionId: k,//__tmpAnswer[k]['questionDetailId'],
                    answer: JSON.stringify(__tmpAnswer[k]),// JSON.stringify(answerN),
                    active: true,
                    duration: __hms,
                    expire: __expire,
                    categoryId : this.state.theory.categoryId,
                    subcategoryId : this.state.theory.subcategoryId,
                    sumAnswer: '',
                    score: '',
                });
                return k;
            });
        }
        else {
            dataAnswer.push({
                userId: this.state.userId,
                theoryId: this.state.theoryId,
                questionId: 0,
                answer: JSON.stringify({}),
                active: true,
                duration: __hms,
                expire: __expire,
                categoryId : this.state.theory.categoryId,
                subcategoryId : this.state.theory.subcategoryId,
                sumAnswer: '',
                score: '',
            });
        }


        let multiplePromisePut = [];

        dataAnswer.map(v=>{
            if(v){
                multiplePromisePut.push(
                    Api.post('/answers/add',v).then(res=> {
                        return res;
                    }).catch(err=>{
                        return err;
                    })
                )
            }
            return v;
        });

        Promise.all(multiplePromisePut).then(resp=>{
           // Update data questions
           let __answerId = [];
           let __status = 200;
           let __err = [];
           if(resp.length){
               resp.map(v=>{
                  if(v.id){
                      __answerId.push(v.id);
                  }
                  if(v.statusCode !== 200){
                      __status = v.statusCode;
                      __err.push(v.message)
                  }
                   return v;
               });
           }

           if(__status === 200) {

               Api.put('/questions/answers/' + this.state.id, {
                   theoryId: this.state.theoryId,
                   answerId: JSON.stringify(__answerId),
                   userId: this.state.userId,
                   active: false,
               }).then(resp => {
                   this.setState({
                       loading: false,
                       loadingButton: false
                   });

                   this.handleGo('/schedules')

                   this.showMessage(true, 'Answer tes taker complete ');

               }).catch(err => {
                   let errors = {};
                   Object.keys(err).map((item) => {
                       if (item) {
                           errors[item] = err[item][0] ? err[item][0] : err[item];
                       }
                       return item;
                   });
                   this.setState({
                           errors: errors ? errors : {},
                           loadingButton: false
                       }
                   );
                   this.showMessage(false, err.message);
               });
           }
           else{
               this.showMessage(false,'Jaringan trputus, segera hubungi team IT kami, jangan keluar dari halaman' +
                   ' ini. Error code (201) \n'+__err.join(','), false)
           }

        }).catch(err=>{
        });
    };

    NumberFormatCustom = (props) => {
        const { inputRef, onChange, ...other } = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator
                isNumericString
            />
        );
    };

    handleAnswer = (e)=>{

        let __ansTmp = this.state.answersToPut;
        let checkedData = this.state.rows;


        if(this.state?.theory?.view === 'Table'){
            const {question, answer } = e;

            answer['code'+answer.label] = question.code;
            //
            // if(answer.answer && Object.keys(answer.answer).length){
            //     answer.answer[answer.code] = question;
            // }
            // else{
            //     answer.answer = {[answer.code]:question};
            // }
            //
            // // __ansTmp[answer.questionDetailCode] = answer;

            if(
                __ansTmp[answer.questionDetailId] &&
                Object.keys(__ansTmp[answer.questionDetailId]).length
            ){
                __ansTmp[answer.questionDetailId][answer.label ?? answer.code] = {
                    answer: question.question,
                    code: question.code,
                    label: answer.label
                };
            }
            else {
                __ansTmp[answer.questionDetailId] = {
                    [answer.label ?? answer.code ]:{
                        answer: question.question,
                        code: question.code,
                        label: answer.label
                    }
                };
            }




            if(checkedData.length){
                checkedData.map(v=>{
                    if(v.code === answer.questionDetailCode){
                        v.answers.map((v1 )=>{
                            if(v1.questionDetailCode === answer.questionDetailCode && v1.code === answer.code){
                                v1.selected = true;
                            }
                            return v1;
                        });
                    }
                    return v;
                })
            }
        }
        else {
            // __ansTmp[e.questionDetailCode] = e;

            __ansTmp[e.questionDetailId] = {
                [e.label ?? e.code ]: {
                    answer: e.answer,
                    code: e.code,
                    label: e.label
                }
            };

            if (checkedData.length) {
                checkedData.map(v => {
                    if (v.code === e.questionDetailCode) {
                        v.answers.map((v1) => {
                            if (v1.questionDetailCode === e.questionDetailCode && v1.code === e.code) {
                                v1.selected = true;
                            } else {
                                v1.selected = false;
                            }
                            return v1;
                        });
                    }
                    return v;
                })
            }

        }

        this.setState({
            answersToPut: __ansTmp,
            rows: checkedData,
        },()=>{
            if(Object.keys(__ansTmp).length ===  this.state.rows.length){
                // clearInterval(this.myInterval);
                let checkNextQuest = true;

                if(this.state.theory.view === 'Table'){
                    this.state.rows.map(n=> {
                        n.answers.map((v, k) => {
                            if (v.selected === false) {
                                checkNextQuest = false;
                            }
                            return v;
                        })
                        return n;
                    })
                }
                if(checkNextQuest) {
                    if (this.state.theory.view  === 'Kraepelin'){
                        const {kraepelinSession:sessionActive, sessionsTimer } = this.state;
                        if(sessionActive >= sessionsTimer.length){
                            this.showMessage(true, 'Thanks, Your answers it`s completed!\n, Please click continues to save answer.');
                            this.setState({
                                nextToSave: true
                            });
                        }
                    }
                    else{
                        this.showMessage(true, 'Thanks, Your answers it`s completed!\n, Please click continues to save answer.');

                        this.setState({
                            nextToSave: true
                        });
                    }
                }
            }
        });

    }

    onChangeInput = (e,qIndex,aIndex) => {
        let nChange = this.state.rows;
        if (this.state.theory.view  === 'Kraepelin'){
            // this.showMessage(true, 'input data is '+ e.target.value)

            // return false;
            if (nChange && nChange[aIndex.currentQuestion] && nChange[aIndex.currentQuestion].answers[aIndex.rowId] && nChange[aIndex.currentQuestion].answers[aIndex.rowId][aIndex.columnId]) {
                nChange[aIndex.currentQuestion].answers[aIndex.rowId][aIndex.columnId].answer = e.target.value;
                nChange[aIndex.currentQuestion].answers[aIndex.rowId][aIndex.columnId].label = e.target.value;

                // this.__changeSessionKraepelin(aIndex);

            }

        }
        else {
            if (nChange && nChange[qIndex] && nChange[qIndex].answers[aIndex]) {
                nChange[qIndex].answers[aIndex].answer = e.target.value;
                nChange[qIndex].answers[aIndex].label = e.target.value;
            }
        }


        this.setState({
            rows:nChange
        },()=>{
        });
    }

    __changeSessionKraepelin = (aIndex) =>{

        // let nChange = this.state.rows;
        //
        // let sessionActive = this.state.kraepelinSession;

        const {counterTimeKrp, kraepelinSession:sessionActive, sessionsTimer, row:nChange } = this.state;


        let nextSession = true;
        if(sessionsTimer.length && sessionsTimer[sessionActive] === counterTimeKrp){
            // let dataSelect = nChange[sessionActive].answer;
            // __ansTmp[e.questionDetailId] = {
            //     [e.label ?? e.code ]: {
            //         answer: e.answer,
            //         code: e.code,
            //         label: e.label
            //     }
            // };
            if((sessionActive + 1) >= sessionsTimer.length && this.state.theory.view === 'Kraepelin') {
                this.showMessage(true, 'Thanks, Your answers it`s completed!\n, Please click continues to save answer.');
                this.setState({
                    nextToSave: true,
                    kraepelinSession: sessionActive + 1
                });
            }
            else {
                this.setState({
                    nextToSave: false,
                    kraepelinSession: sessionActive + 1
                },()=>{
                })
            }
        }


        // if(nChange[sessionActive] && nChange[sessionActive]?.answers?.length) {
        //     nChange[sessionActive].answers.map(qI => {
        //         if (qI && qI.length && qI[sessionActive] && qI[sessionActive].answer === '') {
        //             nextSession = false;
        //         }
        //         return qI;
        //     });
        //
        //     if (nextSession) {
        //         sessionActive + 1;
        //
        //         this.setState({
        //             kraepelinSession: sessionActive
        //         },()=>{
        //         });
        //     }
        //
        // }

    }

    render() {

        let lang = {};
        if(this.props.toggle_set_lang === 'indo') {
            lang = languange.id;
        }else {
            lang = languange.en;
        }
        const text = lang;

        return (

            <div className='dashboard-detail'>
                <nav aria-label='breadcrumb'>
                    <ol className='breadcrumb'>
                        <li className='breadcrumb-item'><Link to='/' >{text.label_dashboard}</Link></li>
                        <li className='breadcrumb-item active' aria-current='page'>{text.label_questions}</li>
                    </ol>
                </nav>

                {(this.state.minutes === 0 && this.state.seconds === 0) && (
                    <div className='alert-heading purple background-reddish p-12 color-white'>
                        <i className='fas fa-exclamation-circle mr-3'> </i>
                        {text.reservation_time_up}
                    </div>
                )}

                <MultipleChoice
                    dataQuestions={this.state.rows}
                    onAnswer={this.handleAnswer}
                    loading={this.state.loading}
                    startTimer={this.__startTimer}
                    labelNext={text.button_next}
                    reload={this.state.startedTime}
                    handleDone={this.handleSubmit}
                    minutes={this.state.minutes}
                    seconds={this.state.seconds}
                    hours={this.state.hours}
                    countdown={this.state.countdown}
                    typeView={this.state?.theory?.view}
                    nextToSave={this.state.nextToSave}
                    onChangeInput={this.onChangeInput}
                    kraepelin={this.state.kreapelin}
                    kraepelinSession={this.state.kraepelinSession}
                    sessionsTimer={this.state.sessionsTimer}
                />

                <ToastContainer />
            </div>

        )

    }
}

export const DivCount = (props)=>{
    return (
        <div style={{ marginBottom: 80 }}>
            {/*<hr style={{ border: "2px solid #ddd" }} />*/}
            <div style={{ marginTop: 30, display: "flex", }}>
                <div style={{ width: "30%", paddingRight: 30 }}>{props.children}</div>
                <div style={{ width: "70%" }}>
                    <h3 className="h5">{props.label}</h3>
                    <p>{props.description}</p>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {

    return {

        toggle_set_lang: state.toggle_set_lang,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

function appendLeadingZeroes(n) {
    if (n <= 9) {
        return "0" + n;
    }
    return n
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Index));
