import React,{Component} from 'react';
import Button from '@material-ui/core/Button';
import SimpleReactValidator from 'simple-react-validator';
import {history} from '../../../../shared/configure-store';
import {toast, ToastContainer} from 'react-toastify';
import {Link, withRouter} from 'react-router-dom';
import Api from '../../../../libraries/api';
import {bindActionCreators} from "redux";
import {RootActions} from "../../../../shared/root-action";
import {connect} from "react-redux";
import languange from "../../../../libraries/label-lang";
import AuthHelper from "../../../../libraries/auth-helper";
import templateId from "../../../../libraries/lang-id";
import _ from 'lodash';
import NumberFormat from "react-number-format";
import Ages from "../../../../libraries/ages";
import moment from 'moment';

SimpleReactValidator.addLocale('indo', templateId);


class QuestionsDetail extends Component {

    constructor(props) {

        super(props);


        let lang = AuthHelper.getLang();

        this.validator = new SimpleReactValidator(
            {
                locale: lang
            }
        );


        this.state = {
            // loading: false,
            loadingButton: false,

            errors: [],

            listTheory  : [
                { id: '', name:'--- Questions ---' },
            ],
            userId      : '',
            listUsers  : [
                { id: '', name:'--- Questions ---' },
            ],

            disableUpdate: true,

            // data taked
            active: false,
            answerId: "",
            bod: null,
            descriptions: "",
            expire: "2021-03-03",
            id: '',
            theory: {},
            theoryId: '',
            user: {},
            age:0,

            testExpire : false,
        }
    }

    componentDidMount(){

        document.title = 'PSIKOTES ONLINE - Add Category';

        const id = Number(this.props.match.params.id);
        const dataS = this.props?.location?.state ?? '';

        this.setState({
            loading: true,
            loadingButton: true,
            id: id,
        });


        if(dataS){
            let setData = {};
            Object.keys(dataS).map(k =>{
                setData[k]= dataS[k] ?? '';
                if(k === 'expire' && setData[k]){
                    let exD = new Date(setData[k]);
                    setData['bod'] = new Date(setData[k]);
                    let nowD = new Date();
                    if(exD < nowD){
                        setData['testExpire'] = true;
                    }
                }
                return k;
            });
            this.setState({
                ...setData,
                loadingButton: false
            },()=>{
            });
        }
        else {
            this.__loadData(id);
        }

    };

    __loadData(id){

        this.setState({loadingButton:true});
        Api.get('/questions/'+id).then(resp => {
            let response = resp?.result?.data ?? {};

            let setData = {};
            Object.keys(response).map(k =>{
                setData[k]= response[k];
                if(k === 'expire' && setData[k]){
                    let exD = new Date(setData[k]);
                    setData['bod'] = new Date(setData[k]);
                    let nowD = new Date();
                    if(exD < nowD){
                        setData['testExpire'] = true;
                    }
                }
                return k;
            });
            this.setState({
                ...setData,
                loadingButton: false
            },()=>{
                // this.__fetchData();
            });
        }).catch(e => {
            this.showMessage(false, e.message);
            this.setState({ loadingButton: false, })
        });
    };

    UNSAFE_componentWillReceiveProps() {

        let lang = AuthHelper.getLang();

        this.validator = new SimpleReactValidator(
            {
                locale: lang
            }
        );
    }

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        });

    };

    handleDate (date, prop){

        this.setState({

            [prop]: date

        })

    };

    handleChecked = name => e => {

        this.setState({

            [name]: e.target.checked ? true : false,

        })

    };

    handleGo  = (link) => {
        history.push(link);
    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    enableUpdate = ()=>{
        this.setState({
            disableUpdate: false
        })
    };

    handleUpload = e => {

        let temp = _.get(e,'target.files[0]',false);

        this.setState({
            errors: {file:''},
        });

        if(temp){
            if(e.target.files[0].size > 999999){

                this.setState({
                    errors: {
                        file: 'This file is too big. Maximum size is 1MB.'
                    },
                });

            }else if(e.target.files[0].name.toLowerCase().search('.jpeg') === -1 &&
                e.target.files[0].name.toLowerCase().search('.jpg') === -1  &&
                e.target.files[0].name.toLowerCase().search('.png') === -1){

                this.setState({
                    errors: {
                        file: 'This file is invalid. Only accept .jpg, .jpeg and .png'
                    },
                });

            }else{

                this.setState({
                    file: URL.createObjectURL(e.target.files[0]),
                    image_file: e.target.files[0],
                    image_name: e.target.files[0].name,
                });

            }
        }
    };

    handleSubmit = (e) => {
        //00-0 1
        //000- 10
        //0-00 3
        let next = '00-0'+this.state.id+'000-'+this.state.theoryId+'0-00'+this.state.userId;

        history.push({
            pathname: '/questions/'+next,
            state: this.state
        })
    };

    NumberFormatCustom = (props) => {
        const { inputRef, onChange, ...other } = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator
                isNumericString
            />
        );
    };

    render() {

        let lang = {};
        if(this.props.toggle_set_lang === 'indo') {
            lang = languange.id;
        }else {
            lang = languange.en;
        }
        const text = lang;

        return (

            <div className='dashboard-detail'>
                <nav aria-label='breadcrumb'>
                    <ol className='breadcrumb'>
                        <li className='breadcrumb-item'><Link to='/' >{text.label_dashboard}</Link></li>
                        <li className='breadcrumb-item'><Link to='/master_questions' >{text.label_master_questions}</Link></li>
                        <li className='breadcrumb-item active' aria-current='page'>{this.state.disableUpdate ? text.label_detail : text.button_edit} {text.label_schedules}</li>
                    </ol>
                </nav>

                <div className='row'>
                    <div className='col-lg-12 col-xl-12'>
                        <div className='card-white'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6 col-ms-12 float-left'>
                                    <h4 className='text-uppercase'>{text.label_master_questions}</h4>
                                    <div className='col-12 mb-2'>
                                        <div className='row m-0'>
                                            <div className='col-4 p-0 mb-2'>
                                                {text.label_nip}
                                            </div>
                                            <div className='col-8 p-0 mb-2'>
                                                : {this.state.user.nip}
                                            </div>
                                            <div className='col-4 p-0 mb-2'>
                                                {text.label_name}
                                            </div>
                                            <div className='col-8 p-0 mb-2'>
                                                : {this.state.user.name}
                                            </div>
                                            <div className='col-4 p-0 mb-2'>
                                                {text.label_gender}
                                            </div>
                                            <div className='col-8 p-0 mb-2'>
                                                : {this.state?.user?.gender ?? '-'}
                                            </div>
                                            <div className='col-4 p-0 mb-2'>
                                                {text.label_address}
                                            </div>
                                            <div className='col-8 p-0 mb-2'>
                                                : {this.state?.user?.address ?? '-'}
                                            </div>
                                            <div className='col-4 p-0 mb-2'>
                                                {text.label_phone}
                                            </div>
                                            <div className='col-8 p-0 mb-2'>
                                                : {this.state?.user?.phone ?? '-'}
                                            </div>
                                            <div className='col-4 p-0 mb-2'>
                                                {text.label_birth_place}
                                            </div>
                                            <div className='col-8 p-0 mb-2'>
                                                : {this.state?.user?.birth_place ?? ''}, {this.state?.user?.birth_date ?? ''}
                                            </div>
                                            <div className='col-4 p-0 mb-2'>
                                                {text.label_age}
                                            </div>
                                            <div className='col-8 p-0 mb-2'>
                                                : {Ages.calculateOnly(this.state.user.birth_date).years +' '+text.label_years}, {Ages.calculateOnly(this.state.user.birth_date).months +' '+text.label_month}
                                            </div>
                                        </div>
                                        <hr/>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-ms-12 float-left'>
                                    <h4 className='text-uppercase'>{text.label_testing_all} {text.label_theory}</h4>
                                    <div className='col-12 mb-2'>
                                        <div className='row m-0'>
                                            <div className='col-4 p-0 mb-2'>
                                                {text.label_theory_name}
                                            </div>
                                            <div className='col-8 p-0 mb-2'>
                                                : {this.state.theory.name}
                                            </div>
                                            <div className='col-4 p-0 mb-2'>
                                                {text.label_category}
                                            </div>
                                            <div className='col-8 p-0 mb-2'>
                                                : {this.state?.theory?.category?.name ?
                                                this.state?.theory?.category?.name +
                                                (this.state?.theory?.subcategory?.name ? ' - '+this.state?.theory?.subcategory?.name : '')
                                                : ''}
                                            </div>
                                            <div className='col-4 p-0 mb-2'>
                                                {text.label_duration}
                                            </div>
                                            <div className='col-8 p-0 mb-2'>
                                                : {this.state?.theory?.duration ?? 0} {text.label_minute}
                                            </div>
                                            <div className='col-4 p-0 mb-2'>
                                                {text.label_questions_expire}
                                            </div>
                                            <div className='col-8 p-0 mb-2'>
                                                : {this.state?.expire ? moment(this.state?.expire).format('DD-MM-YYYY : hh:mm') : ''}
                                            </div>
                                            {/*{this.state?.theory?.descriptions  && <div*/}
                                            {/*    className='col-12 p-0 mb-2 text-justify'*/}
                                            {/*    dangerouslySetInnerHTML={{__html: this.state.theory.descriptions.split('\n').join('<br/>')}}*/}
                                            {/*></div>}*/}
                                            <div className='col-4 p-0 mb-2'>
                                                {text.note_heading}
                                            </div>
                                            <div className='col-8 p-0 mb-2'>
                                                : {this.state?.descriptions ?? ''}
                                            </div>
                                        </div>
                                        <hr/>
                                    </div>
                                </div>
                                <h6 className='col-4 mb-2'>
                                    {text.label_theory_descriptions} :
                                </h6>
                                <div className='col-8 mb-2'></div>
                                {this.state?.theory?.descriptions && <div
                                    className='col-12 mb-2 text-justify'
                                    dangerouslySetInnerHTML={{__html: this.state.theory.descriptions.split('\n').join('<br/>')}}
                                ></div>}

                                <div className='col-12 text-center mt-4'>
                                    <Button
                                        variant='contained'
                                        className='mr-3'
                                        onClick={() => this.handleGo('/schedules')}
                                    >
                                        {text.button_back}
                                    </Button>
                                    {this.state.testExpire === false &&
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        className='mr-3'
                                        onClick={this.handleSubmit}
                                        // onClick={()=>{
                                        // }}
                                        disabled={(this.state.loadingButton || this.state.answerId !== '')}
                                    >
                                        { this.state.answerId !== '' ? text.label_testing_after :
                                            text.label_testing_all+' '+text.label_now} {this.state.loadingButton &&
                                    <i className='fa fa-spinner fa-spin'> </i>}
                                    </Button>
                                    }
                                    {this.state.testExpire === true &&
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        className='mr-3'
                                        disabled
                                    >
                                        { text.label_schedules_done}
                                    </Button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ToastContainer />
            </div>

        )

    }
}

const mapStateToProps = (state) => {

    return {

        toggle_set_lang: state.toggle_set_lang,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(QuestionsDetail));
