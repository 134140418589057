import React,{ Component } from 'react';
import PropTypes from "prop-types"
import { withRouter, Link } from 'react-router-dom';
import {bindActionCreators} from "redux";
import Logo from '../../../images/logo-uai-ypi-big-300x97.png';
import {RootActions} from "../../../shared/root-action";
import {connect} from "react-redux";
import AuthHelper from "../../../libraries/auth-helper";
import languange from '../../../libraries/label-lang';
import {history} from "../../../shared/configure-store";
import Api from "../../../libraries/api";

class Sidebar extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            menus: [],
            balance: '',
            credit: '',
            users:'',
            listRules:{}
        };
    }

    componentDidMount() {
        this.handleProfile();

        Api.get('/roles').then(res => {
            let dataRoles ={};
            let response = res.result.data;

            response.map(v=>{
                if(v.code){
                    dataRoles[v.code]=v;
                }
                return v;
            })
            this.setState({
                listRules: dataRoles,
                loading: false,
            })
        }).catch((err) => {
            this.setState({
                loading: false,
            });
            // this.showMessage(false, err && err.message ? err.message : 'address not found!');
        });
    }

    handleProfile = () =>{
        let x = AuthHelper.getProfile();
        if(x.name){
        }
        this.setState({
            users:x
        });
    }

    handleLogout = () => {

        AuthHelper.logOut();

    };

    handleGo  = (link) => {
        history.push(link);
    };


    render() {
        const { location } = this.props;
        let relocate = location.pathname.split('/')[1];
        relocate = relocate ? relocate : '/';

        let lang = {};
        if(this.props.toggle_set_lang === 'indo') {
            lang = languange.id;
        }else {
            lang = languange.en;
        }
        const text = lang;


        // const access = this.state.users.akses ? this.state.users.akses : '';
        const access = this.state.users.role ? this.state.users.role : '';

        return(

            <aside className={"side-nav " + (this.props.toggle_sidebar_state ? "show" : "")  } >
                <div className='header-logo'>
                    {/*<img src={Logo} alt='logo' />*/}
                    <div className='col-md-12 col-lg-12 px-lg-12 text-center text-md-left'>
                        <img src={Logo} alt='logo' className='' />
                        {/* <h5 className='title mb-0' style={{'fontStyle': 'italic', 'color': 'whitesmoke'}}><u>PSIKOTES ONLINE</u></h5> */}
                        {/* <h6 className='sub-title' style={{'fontStyle': 'italic', 'color': 'whitesmoke'}}>Psikotes</h6> */}
                    </div>
                </div>

                <ul className='nav flex-column mt-3'>
                    <div className='card-sidenav'>
                        <div className='card-header'>
                            <i className='fas fa-user'> </i>
                            <span>{this.state.users.username}</span>
                            <i className='fa fa-users-cog float-right' onClick={()=>history.push('/me')} />
                        </div>
                        <div className='card-body'>
                            <p className='mb-2'>{text.label_nip} : {this.state.users.nip}</p>
                            <p className='mb-2'>{text.label_name} : {this.state.users.name}</p>
                            <p className='mb-2'>{text.label_address} : {this.state.users.address}</p>
                        </div>
                    </div>
                    {/*Dashboard*/}
                    <li className={relocate === '/' ? 'nav-item active' : 'nav-item'}>
                        {/*<Link to='/histories'><i className='fas fa-home'> </i> {text.label_dashboard}</Link>*/}
                        <Link to='/'><i className='fas fa-home'> </i> {text.label_dashboard}</Link>
                    </li>
                    {/*Company*/}
                    {/*{access === 1 &&*/}
                    {/*<li className={relocate === '/company' ? 'nav-item active' : 'nav-item'}>*/}
                    {/*    /!*<Link to='/histories'><i className='fas fa-home'> </i> {text.label_dashboard}</Link>*!/*/}
                    {/*    <Link to='/company'><i className='fas fa-cogs'> </i> {text.label_company}</Link>*/}
                    {/*</li>*/}
                    {/*}*/}
                    {/*Manage Account*/}
                    {(access === 1 || access === 2 ) &&
                    <li className={relocate === 'manage_users' ? 'nav-item active' : 'nav-item'}>
                        <Link to='/manage_users'><i className='fas fa-users-cog'> </i> {text.label_manage_users} </Link>
                    </li>
                    }
                    {/*Master Category*/}
                    {(access === 1 || access === 2 ) &&
                    <li className={relocate === 'master_category' ? 'nav-item active' : 'nav-item'}>
                        <Link to='/master_category'><i className='fas fa-marker'> </i> {text.label_master_category}
                        </Link>
                    </li>
                    }
                    {/*Master Subcategory*/}
                    {(access === 1 || access === 2 ) &&
                    <li className={relocate === 'master_subcategory' ? 'nav-item active' : 'nav-item'}>
                        <Link to='/master_subcategory'><i className='fas fa-map-pin'> </i> {text.label_master_subcategory}
                        </Link>
                    </li>
                    }
                    {/*Master Theories*/}
                    {(access === 1 || access === 2 ) &&
                    <li className={relocate === 'master_theory' ? 'nav-item active' : 'nav-item'}>
                        <Link to='/master_theory'><i className='fas fa-book'> </i> {text.label_master_theory}
                        </Link>
                    </li>
                    }
                    {(access === 1 || access === 2 ) &&
                    <li className={relocate === 'master_questions' ? 'nav-item active' : 'nav-item'}>
                        <Link to='/master_questions'><i className='fas fa-address-book'> </i> {text.label_master_questions}
                        </Link>
                    </li>
                    }
                    <li className={relocate === 'schedules' ? 'nav-item active' : 'nav-item'}>
                        <Link to='/schedules'><i className='fas fa-tasks'> </i> {text.label_schedules}
                        </Link>
                    </li>


                    <li className='nav-item'>
                        <button onClick={this.handleLogout}><i className='fas fa-sign-out-alt'> </i> {text.label_logout}</button>
                    </li>

                </ul>
                {/*<ul className="nav flex-column">
                    <li className="nav-item">
                        <a className="nav-link active"
                           href="#">Active</a>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle"
                           data-toggle="dropdown"
                           href="#"
                           role="button"
                           aria-haspopup="true"
                           aria-expanded="false">Dropdown</a>
                        <div className="dropdown-menu">
                            <a className="dropdown-item"
                               href="#">Action</a>
                            <a className="dropdown-item"
                               href="#">Another action</a>
                            <a className="dropdown-item"
                               href="#">Something else here</a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item"
                               href="#">Separated link</a>
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link"
                           href="#">Link</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link disabled"
                           href="#">Disabled</a>
                    </li>
                </ul>*/}

            </aside>

        )

    }

}

const mapStateToProps = (state) => {

    return {

        toggle_sidebar_state: state.toggle_sidebar_state,

        toggle_set_lang: state.toggle_set_lang,

        profile_state: state.profile_state,

        permission_state: state.permission_state,


    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar));
