import React,{Component} from 'react';
import Button from '@material-ui/core/Button';
import {history} from '../../../../shared/configure-store';
import {connect} from 'react-redux';
import {RootActions} from '../../../../shared/root-action';
import {toast, ToastContainer} from 'react-toastify';
import {bindActionCreators} from 'redux';
import Api from '../../../../libraries/apiKoprasi';
import languange from "../../../../libraries/label-lang";
import {Link} from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import AuthHelper from "../../../../libraries/auth-helper";
import SimpleReactValidator from "simple-react-validator";

require('dotenv').config();

class CategoryDetail extends Component {
    constructor(props) {

        super(props);

        let lang = AuthHelper.getLang();

        this.validator = new SimpleReactValidator(
            {
                locale: lang
            }
        );

        this.state = {
            loading: false,
            loadingButton: false,
            showButton: false,

            errors: [],

            id: '',
            name: '',
            descriptions: '',

            disableUpdate: true,

        }
    }

    componentDidMount() {
        document.title = 'PSIKOTES ONLINE - Detail';

        this.setState({
            loading: true,
        });
        const id = Number(this.props.match.params.id);

        Api.get('/categories/'+id).then(resp => {
            // let data = resp;
            // if (data.id) {
            //
            //     this.setState({
            //         loading: false,
            //         id: data.id,
            //         name: data.name,
            //         descriptions: data.descriptions,
            //     });
            // }
            // else {
            //     this.setState({
            //         loading: false,
            //     });
            // }
            let response = resp.result.data;
            let setData = {};

            Object.keys(response).map(k =>{
                setData[k]= response[k];
                return k;
            });

            this.setState({
                loading: false,
                ...setData
            },()=>{
            });
        }).catch(err => {
            this.setState({
                loading: false,
            });
            this.showMessage(false, err.message);
        });
    };

    UNSAFE_componentWillReceiveProps() {

        let lang = AuthHelper.getLang();

        this.validator = new SimpleReactValidator(
            {
                locale: lang
            }
        );
    }

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        });

    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    enableUpdate = ()=>{
      this.setState({
          disableUpdate: false
      })
    };

    handleGo  = (link) => {

        history.push(link);
    };

    handleSubmit = (e) => {

        e.preventDefault();

        if (!this.validator.allValid()) {

            this.setState({
                    errors: this.validator.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true
            }
        );

        let params = {
            name: this.state.name,
            descriptions: this.state.descriptions
        };

        Api.put('/categories/'+this.state.id,params).then(resp => {

            this.setState({
                loadingButton: false,
            });

            this.handleGo('/master_category');
            this.showMessage(true, 'Category successfully updated!');

        }).catch(err => {

            let errors = {};
            Object.keys(err).map((item) => {
                if(item){
                    errors[item] = err[item][0] ? err[item][0] : err[item];
                }
                return item;
            });

            this.setState({
                    errors: errors ? errors : {},
                    loadingButton: false
                }
            );

            this.showMessage(false, err.message);

        });


    };

    render() {

        let lang = {};
        if(this.props.toggle_set_lang === 'indo') {
            lang = languange.id;
        }else {
            lang = languange.en;
        }
        const text = lang;

        return (
            <div className='dashboard-detail'>
                <nav aria-label='breadcrumb'>
                    <ol className='breadcrumb'>
                        <li className='breadcrumb-item'><Link to='/' >{text.label_dashboard}</Link></li>
                        <li className='breadcrumb-item'><Link to='/master_category' >{text.label_master_category}</Link></li>
                        <li className='breadcrumb-item active' aria-current='page'>{this.state.disableUpdate ? text.label_detail : text.button_edit}</li>
                    </ol>
                </nav>

                <div className='row'>
                    <div className='col-lg-8 col-xl-7'>
                        <div className='card-white'>
                            <h2 className='text-uppercase'>{this.state.disableUpdate ? text.label_detail : text.button_edit} {text.label_category}</h2>
                            <form name='add' id='addProduct' className='row' noValidate>
                                <div className='col-12 mb-2'>
                                    <div className='form-group'>
                                        <TextField
                                            type='text'
                                            id='name'
                                            name='name'
                                            label={text.label_category_name}
                                            onChange={(e) => this.handleChange(e, 'name')}
                                            value={this.state.name}
                                            fullWidth
                                            disabled={this.state.disableUpdate}
                                        />
                                        {this.validator.message('name', this.state.name, 'required')}
                                        <div className='text-danger'>{this.state.errors.name}</div>
                                    </div>
                                    <div className='form-group'>
                                        <TextField
                                            type='text'
                                            id='descriptions'
                                            name='descriptions'
                                            label={text.label_category_descriptions}
                                            onChange={(e) => this.handleChange(e, 'descriptions')}
                                            value={this.state.descriptions}
                                            fullWidth
                                            multiline
                                            disabled={this.state.disableUpdate}
                                            rows={4}
                                        />
                                        {/*{this.validator.message('descriptions', this.state.descriptions, 'required')}*/}
                                        <div className='text-danger'>{this.state.errors.descriptions}</div>
                                    </div>
                                </div>
                                <div className='col-12 text-center mt-4'>
                                    <Button
                                        variant='contained'
                                        className='mr-3'
                                        onClick={() => this.handleGo('/master_category')}
                                    >
                                        {text.button_back}
                                    </Button>

                                    {this.state.disableUpdate ?
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            className='mr-3'
                                            onClick={this.enableUpdate}
                                            disabled={this.state.loadingButton}
                                        >
                                            {text.button_edit} {this.state.loadingButton &&
                                        <i className='fa fa-spinner fa-spin'> </i>}
                                        </Button>
                                        :
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            className='mr-3'
                                            onClick={this.handleSubmit}
                                            disabled={this.state.loadingButton}
                                        >
                                            {text.label_save} {this.state.loadingButton &&
                                        <i className='fa fa-spinner fa-spin'> </i>}
                                        </Button>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <ToastContainer />
            </div>
        );
    }
}


const mapStateToProps = (state) => {

    return {

        profile_state: state.profile_state,

        toggle_set_lang: state.toggle_set_lang,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryDetail);