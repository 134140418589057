import React,{Component} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '../../../presentational/table-pagination-actions';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import AuthHelper from '../../../../libraries/auth-helper';
import CircularProgress from '@material-ui/core/CircularProgress';
import {history} from '../../../../shared/configure-store';
import {toast, ToastContainer} from 'react-toastify';
import { withRouter, Link } from 'react-router-dom';
import Api from '../../../../libraries/api';
// import ApiKoprasi from '../../../../libraries/apiKoprasi';
import languange from '../../../../libraries/label-lang';
import {bindActionCreators} from 'redux';
import {RootActions} from '../../../../shared/root-action';
import {connect} from 'react-redux';

import SimpleReactValidator from "simple-react-validator";
import { SearchInput } from "../../styling";
import moment from "moment";

import {
    // JsonToCsv,
    useJsonToCsv
} from 'react-json-csv';
import TextField from "@material-ui/core/TextField/TextField";
import MenuItem from "@material-ui/core/MenuItem";

const { saveAsCsv } = useJsonToCsv();

// import NumberFormat from 'react-number-format';


class MasterQuestions extends Component {


    constructor(props) {
        let lang = AuthHelper.getLang();

        super(props);

        this.state = {
            loading: false,
            loadingButton: false,

            errors: [],

            showDialog: false,
            idDelete: '',
            currDelete: '',

            orderBy: '',
            sortedBy: '',
            searchBy: '',
            keyword: '',

            rows: [],
            total: 0,
            perPage: 10,
            currentPage: 1,
            currentPageTable: 0,

            warehouses: [],

            showActivate :false,
            idChange: '',
            currChange: '',
            dataChange:'',
            accessChange: '',

            listUsers  : [
                { id: '', name:'--- Questions ---' },
            ],
        };

        this.validator = new SimpleReactValidator(
            {
                locale: lang
            }
        );
    }

    componentDidMount() {
        document.title = 'PSIKOTES ONLINE - Master Kategori';
        this._loadData()
    };

    _loadData = _ => {
        this.setState({
            loading: true,
            rows:[]
        })
        const { perPage, currentPage, keyword } = this.state

        const params = {//pagination:{
            ...(perPage ? { size: Number(perPage) } : {}),
            ...(currentPage ? { page: Number(currentPage) } : {}),
            ...(keyword ? { userId : keyword } : {}),
        };//};

        Api.post('/questions',params).then(resp => {
            let response = resp?.result ?? {};
            // Object.keys(resp).map(k=>{
            //     if(resp[k] && resp[k].id){
            //         if(resp[k].descriptions.length > 25) {
            //             resp[k].descriptions = resp[k].descriptions.substring(0, 25) + '...';
            //         }
            //         if(resp[k].kategori.length > 0) {
            //             resp[k].kategori = resp[k].kategori[0].name;
            //         }
            //         if(resp[k].file && resp[k].file.length) {
            //             let tmpImg = resp[k].file && resp[k].file.length ? resp[k].file[0] : '';
            //
            //             if(tmpImg.split('/')[0] == 'produk'){
            //                 tmpImg = tmpImg.split('/');
            //                 tmpImg = '/'+tmpImg.join('/');
            //                 resp[k].file = tmpImg;
            //             }
            //             else {
            //                 resp[k].file = tmpImg ? tmpImg : '';
            //             }
            //         }
            //         else{
            //             resp[k].file = resp[k].file && resp[k].file.length ? resp[k].file[0] : '';
            //         }
            //         data.push(resp[k]);
            //     }
            // });

            this.setState({
                rows: response?.data ?? [],
                loading: false,
                // paginations
                perPage: response?.pagination && response.pagination.size ? response.pagination.size : this.state.perPage,
                currentPage: response?.pagination && response?.pagination.currentPage ? response?.pagination.currentPage -1 : this.state.currentPage,
                currentPageTable: response?.pagination && response?.pagination.currentPage ? response?.pagination.currentPage -1 : this.state.currentPageTable,
                total: response?.pagination && response?.pagination.totalItems ? response?.pagination.totalItems : this.state.total,
            });
        })
          .catch(e => {
              this.showMessage(false, e.message);
              this.setState({ loading: false, })
          })


        Api.post('/users', {
            status:true
        }).then(resp => {
            let response = resp?.result ?? {};
            // Object.keys(resp).map(k=>{
            //     if(resp[k] && resp[k].id){
            //         if(resp[k].descriptions.length > 25) {
            //             resp[k].descriptions = resp[k].descriptions.substring(0, 25) + '...';
            //         }
            //         data.push(resp[k]);
            //     }
            // });

            this.setState({
                listUsers: response?.data ?? [],
            });
        }).catch(e => {
        });


    }

    handleClose = () => {
        this.setState({
            showDialog: false,
            showActivate: false
        })
    };

    handleDelete = () => {

        if (this.state.idDelete) {
            Api.delete('/reservations/' + this.state.idDelete, '', false).then(resp => {

                this.setState({
                        showDialog: false,
                    }
                );

                this.showMessage(true, 'Successfully deleted');
                this._loadData()

            }).catch(err => {

                this.setState({
                        showDialog: false
                    }
                );

                this.showMessage(true, 'Successfully deleted');
                this._loadData()
            });
        }
    };

    handleGo = (link) => {
        history.push(link);
    };

    showMessage = (status, message) => {
        if (status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        } else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleOpen = (row) => {
        this.setState({
            showActivate :true,
            idChange: row.id,
            currChange: row.name,
            dataChange: row,
            accessChange: row.active
        })
    };

    handleChange = () => {
        if (this.state.idChange) {
            let lang = {};
            if (this.props.toggle_set_lang === 'indo') {
                lang = languange.id;
            } else {
                lang = languange.en;
            }
            const text = lang;

            Api.put('/questions/status/' + this.state.idChange, {
                active:!this.state.accessChange,
                theoryId: this.state.dataChange.theoryId,
                userId: this.state.dataChange.userId,
            }).then(resp => {

                this.setState({
                        showActivate: false,
                    }
                );

                this.showMessage(true, text.label_data_success+(this.state.accessChange ? text.label_deactivation : text.label_activation));
                this._loadData()

            }).catch(err => {

                this.setState({
                        showActivate: false
                    }
                );

                this.showMessage(true, err.message);
                this._loadData()
            });
        }
    };

    handleSort = (val) => {

        let sortedBy = this.state.sortedBy;

        if (val !== this.state.orderBy) {
            sortedBy = 'asc';
        } else {
            if (sortedBy && sortedBy === 'asc') {
                sortedBy = 'desc';
            } else {
                sortedBy = 'asc';
            }
        }

        this.setState({
            orderBy: val,
            sortedBy: sortedBy,
            loading: true
        }, () => {
            this._loadData()
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            rows: [],
            currentPage: newPage + 1,
            currentPageTable: newPage + 1,
            loading: true,
        }, () => {
            this._loadData()
        });
    };

    handleChangeRowsPerPage = event => {
        this.setState({
            perPage: parseInt(event.target.value, 10),
            currentPage: 1,
            loading: true,
        }, () => {
            this._loadData()
        });
    };


    __handleSearchEvent = (data,props)=>{

        this.setState({
            [props]:data.target.value ? data.target.value : ''
        },()=> {
            this._loadData();
        });
    };


    __handleDownloadToExcel = (post) =>{
        /**
         * const $data = document.getElementById('data')
         const $fileName = document.getElementById('fileName')
                     document.body.addEventListener('click', (e) => {
              if (e.target === $data) {
                $data.setAttribute('contenteditable', 'true')
              } else {
                $data.setAttribute('contenteditable', 'false')
              }
            })
                     function download (exportType) {
              const fileName = $fileName.value
              const data = JSON.parse($data.innerText)
              window.exportFromJSON({ data, fileName, exportType })
            }
         *
         **/

        this.setState({
            loading:true,
            loadingButton: true
        });

        let dataExport = [
            {
                // link: '/questions_detail/example',
                link: '/questions_detail',
                option: {
                    theoryId: post.theoryId,
                }
            },
            {
                link: '/answers/export',
                option: {
                    userId: post.userId,
                    theoryId: post.theoryId,
                    categoryId : post.theory.categoryId,
                    subcategoryId : post.theory.subcategoryId,
                }
            }
        ];

        let multiplePromisePut = [];


        dataExport.map(v=>{
            if(v){
                multiplePromisePut.push(
                    Api.post(v.link,v.option).then(res=> {
                        return res;
                    }).catch(err=>{
                        return err;
                    })
                )
            }
            return v;
        });

        // Api.post('/answers/export', {
        //     userId: post.userId,
        //     theoryId: post.theoryId,
        //     categoryId : post.theory.categoryId,
        //     subcategoryId : post.theory.subcategoryId,
        // }).then(response => {
        Promise.all(multiplePromisePut).then(response=>{

            let exampleSplit = [];

            let feedExample = response[0]?.result?.data ?? [];

            // let fields = {};

            if(post.theory.view === 'Kraepelin'){
                feedExample.map(v => {
                    if (v.id && v.example === false) {
                        let dataQ = JSON.parse(v.question);
                        if(dataQ.length) {
                            dataQ.map(nv=>{
                                nv.question = nv.question.split('\n').join('');
                                nv.question = nv.question.split(',');
                                exampleSplit.push(nv.question);
                                return nv;
                            })
                        }
                    }
                });
            }
            else {
                feedExample.map(v => {
                    if (v.id && v.example === false) {
                        exampleSplit.push(v.id);
                    }
                });
            }

            let dataFeed = response[1]?.result?.data ?? [];
            let __tmpAns = [];

            let fileName = (post?.user?.name ?? '1')+'_'+(post?.theory?.name ?? '001');

            if(dataFeed && dataFeed.length) {
                let duration = '';

                let gParse = dataFeed.map(v => {
                    v.answer = typeof v.answer !== "object" ? JSON.parse(v.answer) : v.answer;
                    duration = v.duration;
                    return v;
                });

                fileName = duration ? fileName + '_(' + duration.toLowerCase() + ')' : fileName;

                if(post.theory.view === 'Kraepelin'){

                    let field_tmp = {};
                    let __tmpQues = [];
                    if (exampleSplit.length) {
                        exampleSplit.map((exD,kexD) => {
                            if(exD && exD.length){
                                let __tmpQ = {};
                                // __tmpQ['id'] = 'No';
                                exD.map((v,k)=>{
                                    field_tmp[k+'a'] = 'Q'+(k+1);
                                    field_tmp[k+'aQ'] = 'A'+(k+1);
                                    __tmpQ[k+'a'] = v;
                                    __tmpQ[k+'aQ'] = ' ';
                                })
                                __tmpQues.push(__tmpQ);

                            }
                            return exD;
                        })
                    }

                    if(gParse && gParse.length) {
                        gParse.map(n => {
                            if (n.answer && n.answer.length) {
                                n.answer.map((l ,lk)=> {
                                    let __tmp = {};
                                    // __tmp['id'] = lk;
                                    l.map((ln,kln)=>{
                                        // __tmp.hasOwnProperty(kln+'a');// = "";
                                        // __tmp.hasOwnProperty(kln+'aQ');// = ln.answer ?? "";
                                        __tmp[kln+'a'] = " ";
                                        __tmp[kln+'aQ'] = ln.answer ?? " ";
                                    })
                                    __tmpAns.push(__tmp);
                                })
                            }
                            return n;
                        })
                    }
                    let __tmpAll = [];
                    if(__tmpQues.length){
                        __tmpQues.map((v,k)=>{
                            if(v){
                                let n1 = v;
                                let n2 = __tmpAns[k];
                                __tmpAll.push(n1);
                                __tmpAll.push(n2);
                            }
                        })
                    }
                    const data = JSON.parse(JSON.stringify(__tmpAll));

                    this.JSONToCSVConvertor(data,fileName,false);
                }
                else {

                    let x = 0;
                    if (exampleSplit.length) {
                        exampleSplit.map(exD => {
                            gParse.map(n => {
                                if (n.questionId === exD) {
                                    Object.keys(n.answer).map(k => {
                                        x++;
                                        __tmpAns.push({
                                            no: x,
                                            questionId: n.questionId,
                                            label: n.answer[k].label,
                                            answer: n.answer[k].answer.split('\n').join('').split('<br/>').join('')
                                        });
                                        return k;
                                    })
                                }
                                return n;
                            })
                            return exD;
                        })
                    }

                    let fields = {
                        "no": "No",
                        "questionId": "Kode",
                        "label": "Jawaban",
                        "answer": "Isi Jawaban"
                    };

                    this.__exportFile({data:__tmpAns,fields:fields,filename:fileName});

                }

                this.setState({
                    loading:false,
                    loadingButton: false
                });
            }
            else{
                this.showMessage(false,'Data jawaban tidak ditemukan!');

                this.setState({
                    loading:false,
                    loadingButton: false
                });
            }

        });

    }

    JSONToCSVConvertor = (JSONData, ReportTitle, ShowLabel) => {
        //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
        var arrData =
            typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;

        var CSV = "";

        //This condition will generate the Label/Header
        if (ShowLabel) {
            var row1 = "";

            //This loop will extract the label from 1st index of on array
            for (var index1 in arrData[0]) {
                //Now convert each value to string and comma-seprated
                row1 += index1 + ",";
            }

            row1 = row1.slice(0, -1);

            //append Label row with line break
            CSV += row1 + "\r\n";
        }

        //1st loop is to extract each row
        for (var i = 0; i < arrData.length; i++) {
            var row = "";

            //2nd loop will extract each column and convert it in string comma-seprated
            for (var index in arrData[i]) {
                row += '"' + arrData[i][index] + '",';
            }

            row.slice(0, row.length - 1);

            //add a line break after each row
            CSV += row + "\r\n";
        }

        if (CSV === "") {
            alert("Invalid data");
            return;
        }

        //Generate a file name
        var fileName = "MyReport_";
        //this will remove the blank-spaces from the title and replace it with an underscore
        fileName += ReportTitle.replace(/ /g, "_");

        //Initialize file format you want csv or xls
        var uri = "data:text/csv;charset=utf-8," + escape(CSV);

        // Now the little tricky part.
        // you can use either>> window.open(uri);
        // but this will not work in some browsers
        // or you will not get the correct file extension

        //this trick will generate a temp <a /> tag
        var link = document.createElement("a");
        link.href = uri;

        //set the visibility hidden so it will not effect on your web-layout
        link.style = "visibility:hidden";
        link.download = fileName + ".csv";

        //this part will append the anchor tag and remove it after automatic click
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    __exportFile = async (params) =>{
        const data = params.data;
        const fields = params.fields;
        const filename = params.filename;

        let x = saveAsCsv({data, fields, filename});
        return x;
    }


    render() {
        let lang = {};
        if (this.props.toggle_set_lang === 'indo') {
            lang = languange.id;
        } else {
            lang = languange.en;
        }
        const text = lang;
        // const warehouse_data = useFetch("/warehouses");

        return (

            <div className='dashboard-detail'>
                {/*<JsonToCsv*/}
                {/*    data={this.state.rows}*/}
                {/*    filename={'filename'}*/}
                {/*    fields={{test:'Test',index:'Index'}}*/}
                {/*    // style={style}*/}
                {/*    text={text}*/}
                {/*/>*/}
                <nav aria-label='breadcrumb'>
                    <ol className='breadcrumb'>
                        <li className='breadcrumb-item'><Link to='/' >{text.label_dashboard}</Link></li>
                        <li className='breadcrumb-item active' aria-current='page'>{text.label_master_questions}</li>
                    </ol>
                </nav>
                <h2 className='text-uppercase'>{text.label_master_questions}
                    <Button
                        variant='contained'
                        color='primary'
                        className='float-right mb-3'
                        onClick={() => this.handleGo('/master_questions/add')}
                    >
                        {text.button_add} {text.label_master_questions}
                    </Button>
                </h2>

                <div className='d-inline-flex align-items-center w-100 justify-content-between my-2'>
                    {/*<SearchInput*/}
                    {/*    placeholder={text.label_browse + ' ' + text.label_questions}*/}
                    {/*    value={this.state.keyword}*/}
                    {/*    onSubmit={(x) => this.__handleSearchEvent(x, 'keyword')}*/}
                    {/*    onKeyPress={(x) => this.__handleSearchEvent(x, 'keyword')}*/}
                    {/*    onKeyUp={(x) => this.__handleSearchEvent(x, 'keyword')}*/}
                    {/*/>*/}
                    <TextField
                      className='col-md-6 col-sm-12'
                      placeholder={text.label_browse + ' ' + text.label_account}
                      select
                      label={text.label_browse + ' ' + text.label_account}
                      onChange={(e) => this.__handleSearchEvent(e, 'keyword')}
                      value={this.state.keyword}
                      // fullWidth
                    >
                        {this.state.listUsers.map((option, idx) => (
                          <MenuItem key={idx} value={option.id}>
                              {option.name}
                          </MenuItem>
                        ))}
                    </TextField>
                </div>

                <div className='clearfix'> </div>
                <div className='table-wrapper'>
                    <Table className='table-list' size='small' >
                        <TableHead>
                            <TableRow>
                                {/*<TableCell>{text.label_photo || `picture`}</TableCell>*/}
                                <TableCell className='clickable' onClick={() => this.handleSort('userId')} >{text.label_name}
                                    {this.state.orderBy === 'userId' && (
                                        <span className='icon-sort'>{
                                            this.state.sortedBy === 'asc' ? (
                                                <i className='fas fa-sort-up'> </i>
                                            ) : (
                                                <i className='fas fa-sort-down'> </i>
                                            )
                                        }</span>
                                    )}
                                </TableCell>
                                <TableCell className='clickable' onClick={() => this.handleSort('theoryId ')} >{text.label_theory}
                                    {this.state.orderBy === 'theoryId ' && (
                                        <span className='icon-sort'>{
                                            this.state.sortedBy === 'asc' ? (
                                                <i className='fas fa-sort-up'> </i>
                                            ) : (
                                                <i className='fas fa-sort-down'> </i>
                                            )
                                        }</span>
                                    )}
                                </TableCell>
                                <TableCell >{text.label_category}</TableCell>
                                <TableCell align='center' >{text.label_duration}</TableCell>
                                <TableCell className='clickable' onClick={() => this.handleSort('expire')} >{text.label_questions_expire}
                                    {this.state.orderBy === 'expire' && (
                                        <span className='icon-sort'>{
                                            this.state.sortedBy === 'asc' ? (
                                                <i className='fas fa-sort-up'> </i>
                                            ) : (
                                                <i className='fas fa-sort-down'> </i>
                                            )
                                        }</span>
                                    )}
                                </TableCell>
                                <TableCell align='center'>{text.label_status}</TableCell>
                                <TableCell align='center'>{text.label_testing_all}</TableCell>
                                <TableCell align='center'><i className="fas fa-print" ></i></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.loading ? (
                                <TableRow>
                                    <TableCell colSpan={8} align='center' className='py-5'>
                                        <CircularProgress color='primary' />
                                    </TableCell>
                                </TableRow>
                            ) : (
                                this.state.rows && this.state.rows.length === 0 ? (
                                    <TableRow style={{ height: 33 * this.state.perPage }}>
                                        <TableCell colSpan={8} style={{ textAlign: 'center' }}>{text.label_no}</TableCell>
                                    </TableRow>
                                ) : (
                                    this.state.rows.map((row, i) => (
                                        <TableRow
                                            // key={i}
                                            style={{ cursor: 'pointer' }}
                                            onDoubleClick={_ =>{
                                            // onClick={_ => {
                                                history.push({
                                                    pathname: 'master_questions/' + row.id,
                                                    state: row
                                                })
                                            }}
                                            key={row.id}
                                            selected={row.selected}>
                                            {/*<TableCell>*/}
                                            {/*    <img src={row.file ? process.env.REACT_APP_API_SERVER_PRODUCT_URL+'/file'+row.file : Logo2}*/}
                                            {/*     style={{ width: 50, height: 50, background: `lightgrey` }} />*/}
                                            {/*</TableCell>*/}
                                            <TableCell>{row?.user?.name ? row.user.name : '-'}</TableCell>
                                            <TableCell>{row?.theory?.name ? row.theory?.name : '-'}</TableCell>
                                            <TableCell>{
                                                row?.theory?.category?.name ?
                                                    row?.theory?.category?.name + (row?.theory?.subcategory?.name ? ' - '+row?.theory?.subcategory?.name : '')
                                                    : '-'
                                            }</TableCell>
                                            <TableCell><div className='text-center'>{row?.theory?.duration ? row.theory.duration : 0} {text.label_minute}</div></TableCell>
                                            {/*<TableCell><NumberFormat thousandSeparator={true} value={row.hargaJual ? row.hargaJual : row.harga ? row.harga+(row.harga * 20 /100) : 0} displayType={'text'} renderText={value => value} prefix={'Rp. '} /></TableCell>*/}
                                            <TableCell>{row.expire ? moment(row.expire).format('DD MMM YYYY'): '-'}</TableCell>
                                            {/*<TableCell>{row.terjual ? row.terjual : '-'}</TableCell>*/}
                                            <TableCell><div className='text-center'>{row.active ?
                                                <label className='w-100 badge badge-success' onClick={() => this.handleOpen(row)}>Aktif</label> :
                                                <label className='w-100 badge badge-dark' onClick={() => this.handleOpen(row)} >Not Aktif</label>
                                            }</div></TableCell>
                                            <TableCell><div className='text-center'>{row.answerId ?
                                                <label
                                                    className='w-100 badge badge-success col'
                                                    onClick={() => this.handleOpen(row)}
                                                >{text.label_testing_after}</label> :
                                                <label
                                                    className='w-100 badge badge-dark'
                                                    onClick={() => this.handleOpen(row)}
                                                >{text.label_testing_before}</label>
                                            }</div></TableCell>
                                            <TableCell><div className='text-center'>
                                                {row.answerId && <Button
                                                    variant='outlined'
                                                    // className='btn-link'
                                                    className='btn-icon'
                                                    onClick={() => {
                                                        this.__handleDownloadToExcel(row)
                                                    }}
                                                ><i className="fas fa-print"></i>
                                                </Button>}
                                            </div></TableCell>
                                        </TableRow>
                                    ))
                                )
                            )}

                        </TableBody>
                    </Table>
                </div>

                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component='div'
                    className='responsive'
                    count={this.state.total}
                    rowsPerPage={this.state.perPage}
                    page={this.state.currentPageTable}
                    backIconButtonProps={{
                        'aria-label': 'previous page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'next page',
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                />

                <Dialog
                    maxWidth='sm'
                    fullWidth={true}
                    open={this.state.showDialog}
                    onClose={this.handleClose}
                    aria-labelledby='form-dialog-title'>
                    <DialogTitle id='form-dialog-title'>DELETE PROJECT: {this.state.currDelete}</DialogTitle>
                    <DialogContent>
                        {/*<p>{text.text_delete}</p>*/}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='contained'
                            className='mr-3'
                            onClick={this.handleClose}
                        >
                            {text.button_cancel}
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            className=''
                            onClick={this.handleDelete}
                            disabled={this.state.loadingButton && 'disabled'}
                        >
                            {text.button_delete}{this.state.loadingButton && <i className='fa fa-spinner fa-spin'> </i>}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    maxWidth='sm'
                    fullWidth={true}
                    open={this.state.showActivate}
                    onClose={this.handleClose}
                    aria-labelledby='form-dialog-title'>
                    <DialogTitle id='form-dialog-title'>{this.state.accessChange ? text.label_deactivation : text.label_activation} {text.label_questions+' '+text.body_for} "{this.state?.dataChange?.user?.name}" {text.label_now}?</DialogTitle>
                    <DialogContent>
                        {/*<p>{text.text_delete}</p>*/}
                    </DialogContent>
                    <DialogActions className='text-center'>
                        <Button
                            variant='contained'
                            className='mr-3'
                            onClick={this.handleClose}
                        >
                            {text.button_no}
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            className=''
                            onClick={this.handleChange}
                            disabled={this.state.loadingButton && 'disabled'}
                        >
                            {text.button_yes}{this.state.loadingButton && <i className='fa fa-spinner fa-spin'> </i>}
                        </Button>
                    </DialogActions>
                </Dialog>

                <ToastContainer />
            </div>

        )

    }
}

const mapStateToProps = (state) => {

    return {

        toggle_set_lang: state.toggle_set_lang,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MasterQuestions));
