import React,{Component} from 'react';
import {history} from "../../../shared/configure-store";
import {toast, ToastContainer} from "react-toastify";
import Api from "../../../libraries/api";
import AuthHelper from "../../../libraries/auth-helper";

class ContentDashboard extends Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: false,

            errors: [],

            warehouses: [],

            userName: '',
            statusFilter: [],
            statusPast: false,
            classes: [],
            plans: [],

            userLogin:''
        };
    }

    componentDidMount(){

        document.title = 'PSIKOTES ONLINE - Dashboard';

        let x = AuthHelper.getProfile();

        this.setState({
            userLogin: x
        },()=>{
        })
        // Api.get('/warehouses').then(resp => {
        //     if (resp.data) {
        //
        //         this.setState({
        //             warehouses: resp.data,
        //         })
        //
        //     }
        // }).catch(err => {
        //
        //     this.showMessage(false, err.message);
        // });

        //this.__fetchData();
    };

    __fetchData = () => {
        this._isMounted = true;

        this.setState({
            loading: true,
        });
        let client = process.env.REACT_APP_CLIENT;
        let status = this.state.statusFilter.join();
        let route= '';

        if(this.state.statusFilter.length > 0 ) {
            if(this.state.statusPast) {
                route = '/student/passed-classes?org='+client;
            }else {
                route = '/student/classes?org='+client+'&status='+status;
            }

        }else {
            route = '/student/classes?org='+client;
        }

        Api.get(route).then(resp => {
            if (this._isMounted) {

                if (resp.data) {

                    this.setState({
                        classes: resp.data,
                        loading: false,
                    })

                }

            }
        }).catch(err => {
            this.setState({
                loading: false,
            });

            this.showMessage(false, err.message);
        });
    };

    handleChange (e, prop){

        if(prop === 'warehouse') {
            this.setState({

                warehouseName: e.target.value

            })
        }
        this.setState({

            [prop]: e.target.value

        })

    };


    handleGo  = (link) => {
        history.push(link);
    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    render() {
        const access = this.state.userLogin.role ? this.state.userLogin.role : '';
        const name_access = access === 1 ? 'Developer' :
            access === 2 ? 'Administrator' :
                access === 3 ? 'Peserta' : 'Guest';

        return (

            <div className="row justify-content-center">
                <div className="col-md-12">
                    {/*<img src={Logo2} alt='logo' style={{"width":"100px", 'background':'black', 'borderRadius':'10px'}}/>*/}
                    <div className="row">
                        <div className="col-md-12 m-2">
                            <h2>Selamat Datang {this.state.userLogin.name} !</h2>
                            {access === 1 && <p>Anda login sebagai {name_access}<br/>
                                Anda mempunyai semua akses di aplikasi ini.</p>}
                            {access === 2 && <p>Anda login sebagai {name_access}<br/>
                                Anda dapat menamebah dan merubah data soal dan peserta Psikotes.</p>}
                            {access === 3 && <p>Anda login sebagai {name_access}<br/>
                                Anda dapat melakukan test yang telah kami sediakan di menu jadwal test.</p>}
                            <p>Terima kasih atas kunjungannya.</p>
                            {access === 3 && <p>Semoga test anda menyenangkan.</p>}
                        </div>
                    </div>

                </div>
                <ToastContainer />
            </div>

        )

    }
}

export default ContentDashboard;