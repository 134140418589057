import React,{Component} from 'react';
import Button from '@material-ui/core/Button';
import {history} from '../../../../shared/configure-store';
import Logo from '../../../../images/logo-black.png';
import Image from '../../../../images/forgot.png';
import {toast, ToastContainer} from 'react-toastify';
import Copyright from '../../../presentational/copyright';
import {RootActions} from "../../../../shared/root-action";
import {bindActionCreators} from 'redux';
import {connect} from "react-redux";
import languange from "../../../../libraries/label-lang";

require('dotenv').config();

class ForgotSuccess extends Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            email: '',
        };
    }

    componentDidMount() {
        document.title = 'PSIKOTES ONLINE - Forgot Berhasil';
    }

    handleGo  = (link) => {

        history.push(link);
    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    render() {

        let lang = {};
        if(this.props.toggle_set_lang === 'indo') {
            lang = languange.id;
        }else {
            lang = languange.en;
        }
        const text = lang;

        return (
            <main className='success'>
                <section className='my-auto'>
                    <div className='container text-center'>
                        <img src={Logo} alt='logo' className='img-logo' />
                        <img src={Image} alt='forgot' className='pt-3 d-block mx-auto mb-3' />
                        <p className='text-big text-blue'>{text.forgot_success_heading}!</p>
                        <p className='text-center py-4 mb-0'>{text.forgot_success_paragraph}</p>
                        <div className='row justify-content-center'>
                            <div className='col-md-6'>
                                <div className='underline'> </div>
                            </div>
                        </div>
                        <p className='text-center py-4 mb-0'>Untuk pertanyaan atau masalah apa pun silakan email kami di</p>
                        <p className='p-18 text-blue'>contactus@generasi7.com</p>
                        <div className='mt-4 button-small'>
                            <Button
                                variant='contained'
                                onClick={() => this.handleGo('/reservation')}
                                color='primary'
                            >
                                {text.button_back}
                            </Button>
                        </div>
                    </div>
                </section>
                <footer>
                    <p className='text-center absolute-bottom'><Copyright /></p>
                </footer>
                <ToastContainer autoClose={false} />
            </main>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        profile_state: state.profile_state,

        toggle_set_lang: state.toggle_set_lang,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotSuccess);